.contactform {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
  background: #fff;
  background-image: url(/images/main/LeftBack.svg), url(/images/main/RightBack.svg);
  background-position: left, right;
  background-repeat: no-repeat, no-repeat;
  .supporters {
    display: flex;
    margin: 40px;
  }
}
.contactform .contact {
  width: 100%;
  display: flex;
  background: #fff;
  font-size: 18px;
  margin: auto;
  margin-top: 179px; //130px; //topbanner
  padding: 40px;
  border-radius: 10px;
  border: 1px solid rgba(223, 228, 229, 0.658824);
  box-shadow: 4px 25px 45px 0px rgba(112, 135, 139, 0.1);
  background-image: url(/images/PatternPromotion2.png);
  background-position: calc(100% - 40px) 40px;
  background-size: 60px;
  background-repeat: no-repeat;
  max-width: 500px;
}
.contactform .contact .title {
  font-size: 25px;
  margin-bottom: 15px;
  text-align: left;
  max-width: 80%;
  margin-top: 0px;
  color: #09323a;
  font-weight: bold;
  font-style: normal;
  line-height: 30px;
}
.contactform .textInput {
  width: 100%;
  min-width: auto;
}
.contactform .textInput {
  min-width: 395px;
  margin-right: 10px;
  width: 395px;
  height: 50px;
  border-radius: 25px;
  border: dashed 1px #e8eced;
  background: #ffffff;
  box-shadow: 3px 6px 9px rgba(154, 175, 180, 0.08);
  font-size: 15px;
  font-family: 'Gilroy';
  margin: 10px 0;
  padding-left: 25px;
  margin-left: 0px;
  width: 100%;
}
.contactform input.textInput {
  padding-left: 25px;
  margin-left: 0px;
  width: 100%;
  min-width: 100%;
}

.contactform textarea.textInput {
  max-height: 200px;
  min-height: 200px;
  resize: none;
  padding-left: 25px;
  margin-left: 0px;
  width: 100%;
  padding-top: 20px;
}

.contactform .textInput,
.contactform .textInput {
  width: calc(100% - 25px);
  padding-left: 25px;
  margin-left: -10px;
  min-height: 44px;
  max-height: 44px;
  background: #ffffff;
  color: #09323a;
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  border-radius: 25px;
  border: 1px solid rgba(223, 228, 229, 0.658824);
  font-family: 'Gilroy', Arial, sans-serif;
}
.contactform .textInput:hover,
.contactform .textInput:hover {
  opacity: 0.85;
  background: #ffffff;
  border-radius: 25px;
  border: 1px solid #becdd0;
  box-shadow: 0 0 0 4px rgba(213, 225, 228, 0.4);
}
.contactform .textInput:focus,
.contactform .textInput:focus {
  opacity: 0.85;
  background: #ffffff;
  outline: 0;
  border-radius: 25px;
  border: 1px solid #0ac9ef;
  box-shadow: 0 0 0 4px rgba(10, 201, 239, 0.2);
}
.contactform .textInput:focus::placeholder,
.contactform .textInput:focus::placeholder {
  opacity: 0.3;
}
.contact {
  display: flex;
  flex-direction: column;
}

section.faqs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 60vw;
  margin: 75px auto 0 auto;
  z-index: 3;
  padding: 50px 0;
  max-width: 700px;
  h1 {
    font-size: 36px;
  }
  p {
    color: rgba(9, 50, 58, 0.6);
  }
  a {
    color: #23c49b;
    &:hover {
      text-decoration: underline;
    }
  }
}

@media (max-width: 500px) {
  .contactform input.textInput {
    min-width: 100%;
  }
  .contactform textarea.textInput {
    min-width: 100%;
  }
}
@media (max-width: 330px) {
  .contactform .supporters {
    flex-direction: column;
    img {
      margin: 10px;
    }
  }
}
