.downloads {
  display: flex;
  justify-content: center;
  background: linear-gradient(180deg, #fff, #e8fcff);
  padding-bottom: 100px;
  .device {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px 40px;
    > h2 {
      text-align: center;
      font-size: 25px;
      margin-bottom: 30px;
      width: 150px;
      line-height: 32px;
    }
    .apps {
      display: flex;
      flex-direction: row;
      .app {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 20px 40px 20px;
        &:hover .title {
          cursor: pointer;
          text-decoration: underline;
        }
        .title {
          font-size: 18px;
          line-height: 26px;
          margin-bottom: 20px;
          margin-top: 10px;
          text-decoration: none;
        }
        .icon {
          height: 50px;
          width: auto;
          margin-top: 20px;
        }
        .download {
          width: 96px;
          cursor: pointer;
          margin-top: 20px;
        }
      }
    }
  }
}

// Animation
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-420px * 5));
  }
}

.banner-reviews {
  display: flex;
  width: 100%;
  overflow: hidden;
  .banner-reviews-inner {
    flex-direction: row;
    animation: scroll 25s linear infinite;
    display: flex;
    width: calc(420px * 10);
    &:hover {
      animation-play-state: paused;
    }
  }
  .review-card {
    height: 180px;
    min-width: 400px;
    max-width: 400px;
    .author {
      height: 60px;
      img {
        margin: 10px 0;
        height: 40px;
        width: 40px;
      }
      > div {
        width: calc(100% - 80px);
        &.name {
          font-size: 15px;
          line-height: 15px;
        }
        &.description {
          line-height: 15px;
          font-size: 12px;
        }
        &.profile {
          width: 40px;
          margin: 10px 0;
          height: 40px;
          font-size: 20px;
        }
      }
    }
    .review {
      margin-top: 0;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 4; /* number of lines to show */
      line-clamp: 4;
      -webkit-box-orient: vertical;
    }
  }
}
.all-reviews {
  column-count: 5;
  column-gap: 10px;
  background: #f7f9fb;
  padding: 50px 10vw;
}
.review-card {
  position: relative;
  min-width: 220px;
  box-shadow: 2px 4px 3px 0 rgba(206, 213, 220, 0.15);
  border-radius: 10px;
  padding: 18px;
  transition: 0.5s;
  cursor: pointer;
  background: #ffffff;
  margin: 0 10px 10px 10px;
  break-inside: avoid-column;
  &:hover {
    box-shadow: 2px 4px 8px 5px rgba(206, 213, 220, 0.5);
  }
  .platform {
    position: absolute;
    top: 18px;
    right: 18px;
    height: auto;
  }
  .author {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    height: 80px;
    img {
      width: 60px;
      height: 60px;
      object-fit: cover;
      border-radius: 40px;
      margin-right: -25px;
    }
    .profile {
      width: 60px;
      height: 60px;
      border-radius: 40px;
      color: white;
      font-size: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: -25px;
    }
    .name {
      width: 50%;
      font-weight: 600;
      font-style: normal;
      font-size: 18px;
      color: #09323a;
      letter-spacing: 0;
      line-height: 22px;
      white-space: nowrap;
      overflow: hidden;
      display: block;
      text-overflow: ellipsis;
    }
    .description {
      width: 50%;
      opacity: 0.5;
      font-weight: 500;
      font-style: normal;
      font-size: 14px;
      line-height: 20px;
      color: #09323a;
      letter-spacing: 0.15px;
      white-space: nowrap;
      overflow: hidden;
      display: block;
      text-overflow: ellipsis;
    }
    .stars {
      display: flex;
      width: 50%;
      img {
        display: flex;
        height: 15px;
        width: auto;
        margin: 1px;
      }
    }
  }
  .review {
    margin-top: 20px;
    font-size: 15px;
    letter-spacing: 0;
    font-weight: 500;
    color: #47656b;
    font-style: normal;
    line-height: 20px;
    word-break: break-word;
  }
}
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');
:root {
  --zapier-brand: #3d4592;
  --zapier-brand-120: #2c3266;
  --zapier-text: #000;
  --zapier-text-80: #222;
  --zapier-bg-5: rgb(255 255 255 / 30%);
  --zapier-bg-5b: rgb(0 0 0 / 3%);
  --zapier-bg-0: rgb(255 255 255 / 60%);
  --zapier-icon-bg: #fff;
  --zapier-icon-color: rgb(0 0 0 / 40%);
  --zapier-icon-border-color: rgb(0 0 0 / 10%);
  --zapier-service-icon-border-color: rgb(0 0 0 / 10%);
  --zapier-input-border-color: rgb(0 0 0 / 50%);
  --zapier-border-color: rgb(0 0 0 / 30%);
  --zapier-modal-background-color: #fff;
  --zapier-modal-border-color: rgb(0 0 0 / 60%);
  --zapier-zap-state-off-bg: #403f3e;
  --zapier-zap-state-off-text: #fff;
  --zapier-zap-state-on-bg: #0f884e;
  --zapier-zap-state-on-text: #fff;
  --zapier-avatar-bg: #1a4a4c;
  --zapier-avatar-text: #fff;
  --zapier-button-text: #fff;
  --zapier-button-bg: var(--zapier-brand);
  --zapier-elevation-5: 0 5px 10px 0 rgb(0 0 0 / 10%);
  --zapier-elevation-6: 0 5px 10px 0 rgb(0 0 0 / 20%);
  --zapier-elevation-30: 0 10px 20px 0 rgb(0 0 0 / 30%);
  --zapier-elevation-zap: 0 8px 8px -8px rgb(0 0 0 / 20%);
  --zapier-grid-unit: 10px;
  --zapier-button-input-border-radius: 3px;
  --zapier-font-family: 'Inter', Helvetica, arial, sans-serif;
  --zapier-font-size-26px: max(calc(16px * 1.625), 1.625rem);
  --zapier-font-size-20px: max(calc(16px * 1.25), 1.25rem);
  --zapier-font-size-18px: max(calc(16px * 1.125), 1.125rem);
  --zapier-font-size-16px: max(16px, 1rem);
  --zapier-font-size-14px: max(calc(16px * 0.875), 0.875rem);
  --zapier-font-size-12px: max(calc(16px * 0.75), 0.75rem);
}
[data-theme='dark'] {
  --zapier-brand: #90a1ed;
  --zapier-brand-120: #f0f1fa;
  --zapier-text: #fff;
  --zapier-text-80: rgb(255 255 255 / 80%);
  --zapier-bg-5: rgb(255 255 255 / 10%);
  --zapier-bg-5b: rgb(255 255 255 / 3%);
  --zapier-bg-0: rgb(255 255 255 / 10%);
  --zapier-icon-color: rgb(255 255 255 / 70%);
  --zapier-icon-border-color: rgb(0 0 0 / 20%);
  --zapier-border-color: rgb(255 255 255 / 40%);
  --zapier-input-border-color: var(--zapier-border-color);
  --zapier-service-icon-border-color: rgb(0 0 0 / 20%);
  --zapier-button-text: #000;
  --zapier-button-bg: #fff;
  --zapier-modal-background-color: #222;
  --zapier-modal-border-color: rgb(255 255 255 / 40%);
  --zapier-elevation-5: 0 5px 10px 0 rgb(0 0 0 / 60%);
  --zapier-elevation-30: 0 10px 10px -10px rgb(0 0 0 / 80%);
}
@media (max-width: 1400px) {
  .all-reviews {
    column-count: 4;
  }
}

@media (max-width: 1200px) {
  .all-reviews {
    column-count: 3;
  }
}

@media (max-width: 900px) {
  .downloads {
    font-size: 30px;
    flex-direction: column;
  }
  .downloads .device {
    margin: 0 0 80px 0;
  }
  .all-reviews {
    column-count: 2;
  }
}
@media (max-width: 600px) {
  .downloads .device .apps {
    flex-direction: column;
  }
  .all-reviews {
    column-count: 1;
  }
}
@media (max-width: 300px) {
  .all-reviews {
    padding: 50px 0;
  }
}
