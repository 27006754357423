.paragraph-tool {
  overflow: hidden;
  max-width: 1000px;
  margin: auto;
  padding: 140px 10vw 50px 10vw; //100px 10vw 50px 10vw; //topbanner
  width: 100%;
  position: relative;
  background-image: url(/images/LeftBack.svg), url(/images/RightBack.svg);
  background-position:
    -15% 20%,
    115% 20%;
  background-repeat: no-repeat, no-repeat;
  .timer {
    text-align: center;
    font-size: 30px;
    margin: 20px;
  }
  @keyframes blink {
    0% {
      color: #09323a;
    }
    25% {
      color: #09323a;
    }
    50% {
      color: #09323a00;
    }
    75% {
      color: #09323a;
    }
    100% {
      color: #09323a;
    }
  }
  h1 {
    text-align: center;
    font-size: 40px;
  }
  h2 {
    margin-top: 100px;
    text-align: center;
  }
  .description {
    color: #09323a80;
    text-align: center;
    line-height: 1.8;
    font-size: 20px;
  }
  .paragraph {
    border-radius: 15px;
    background: #ffffff;
    box-shadow: 5px 50px 70px 10px rgba(71, 101, 107, 0.1);
    font-size: 24px;
    font-weight: 400;
    line-height: 2;
    margin-top: 40px;

    margin-bottom: 50px;
    padding: 20px;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    .matched-chars {
      color: #4bb543;
    }
    .next-char {
      color: #09323a;
      animation: blink 1s infinite;
      border-bottom: 2px solid #09323a;
    }
    .unmatched-chars {
      color: #09323a;
    }
  }
  .strengths {
    width: 100%;
    .strength {
      width: 33%;
      span:first-child {
        font-size: 40px;
        font-weight: 500;
        color: #09323a;
      }
      img {
        width: auto;
      }
    }
  }
  .inputParagraph {
    border-radius: 15px;
    background: #ffffff;
    box-shadow: 5px 50px 70px 10px rgba(71, 101, 107, 0.1);
    font-size: 24px;
    font-weight: 400;
    width: 100%;
    line-height: 2;
    padding: 10px 20px;
    resize: none;
  }
  .blueButton {
    width: 200px;
    margin: 25px auto;
  }
  .typetest {
    .container {
      display: flex;
      flex-direction: row;
      /* background-image: url(https://storage.googleapis.com/dratini/Background3.png);
            background-position: 80%;
            background-size: cover;
            background-repeat: no-repeat;*/
      .footer {
        width: calc(100% + 80px);
        margin-top: 10px;
        padding-top: 20px;
        margin-left: -40px;
        margin-right: -40px;
        margin-bottom: -40px;
        background: linear-gradient(180deg, #fff, #e8fcff);
        h5 {
          margin: 0;
          margin-left: 40px;
          color: #09323a;
          font-weight: 500;
        }
        .scores {
          margin-top: 20px;
          margin-bottom: 40px;
          table-layout: fixed;
          width: calc(100% - 80px);
          margin-left: 40px;
          text-align: center;
          border-collapse: collapse;
          background-color: #fff;
          box-shadow: 4px 25px 45px 0 rgba(112, 135, 139, 0.2);
          tbody tr:nth-child(odd) {
            background-color: #f2f2f2;
            td span {
              font-size: 7px;
              margin-left: 2px;
            }
          }
        }
      }
      .text {
        width: calc(70% - 40px);
        margin: 0;
        .newsTitle {
          text-align: center;
        }
        .description {
          width: 100%;
        }
        .blueButton {
          margin: 10px auto 0 auto;
          max-width: 120px;
          min-height: 30px;
          font-size: 15px;
        }
      }
      .image {
        width: 30%;
        margin: 20px 20px;
        display: flex;
        flex-direction: column;
        svg {
          display: inline-flex;
          vertical-align: bottom;
          width: 150px;
          height: 150px;
        }
        circle {
          stroke: rgba(#09323a, 0.1);
          stroke-width: 5px;
          stroke-dasharray: 0;
          fill: none;
        }
        .meter {
          stroke-width: 5px;
          stroke: #0ac9ef;
          fill: none;
          transition: stroke-dashoffset 1s cubic-bezier(0.43, 0.41, 0.22, 0.91);
          transform-origin: center center;
          transform: rotate(-90deg) scaleX(-1);
        }
        text {
          fill: #0ac9ef;
          font-weight: bold;
        }
      }
    }
  }
}

@media (max-width: 710px) {
  .newsletter.container {
    max-height: fit-content;
    .image {
      display: flex !important;
    }
    .newsTitle {
      width: 100% !important;
    }
    .scores {
      td {
        &:nth-child(6),
        &:nth-child(7),
        &:nth-child(8),
        &:nth-child(9),
        &:nth-child(10) {
          display: none;
        }
      }
      th {
        &:nth-child(6),
        &:nth-child(7),
        &:nth-child(8),
        &:nth-child(9),
        &:nth-child(10) {
          display: none;
        }
      }
    }
  }
}

.aptitude-test {
  overflow: inherit !important;
  padding: 140px 10vw 50px 10vw; //100px 10vw 50px 10vw; //topbanner
  width: 100%;
  position: relative;
  // user-event: none;
  h1 {
    text-align: center;
    font-size: 40px;
  }
  h2 {
    margin-top: 100px;
    text-align: center;
  }
  a {
    color: #23c49b;
    &:hover {
      text-decoration: underline;
    }
  }
  .banner {
    margin: auto;
    &.sticky {
      max-width: 650px;
      margin-top: 60px;
      position: -webkit-sticky;
      position: sticky;
      bottom: unset;
      top: 120px;
      cursor: unset;
      z-index: 3;
      margin-bottom: -30px;
    }
  }
  .description {
    color: #09323a80;
    text-align: center;
    line-height: 1.8;
    font-size: 20px;
  }
}

.banner {
  &.offer {
    display: flex;
    background: #fff;
    font-size: 15px;
    padding: 30px;
    border-radius: 10px;
    border: 1px solid rgba(223, 228, 229, 0.658824);
    box-shadow: 4px 25px 45px 0px rgba(112, 135, 139, 0.1);
    background-image: url('https://storage.googleapis.com/dratini/Background3.png');
    background-position: right;
    background-size: cover;
    background-repeat: no-repeat;
    width: calc(60%);
    max-width: 750px;
    height: fit-content;
    justify-content: center;
    align-items: center;
    position: relative;
    &.test {
      width: 100%;
      padding-bottom: 10px;
      padding-top: 40px;
      &.type {
        width: 100%;
        max-width: 1250px;
        margin: 100px auto 0 auto;
        //background-image:none;
        .text {
          .subheadline {
            color: #09323a;
          }
        }
        .strengths {
          margin: 10px;
          .strength {
            width: 16%;
            span {
              font-size: 14px;
              font-weight: 600;
              color: #09323a;
            }
            p {
              font-size: 14px;
              //text-align: left;
            }
          }
        }
      }
      .text {
        .top {
          display: flex;
          flex-direction: row;
          .subheadline {
            margin: 0;
            display: flex;
            width: auto;
            font-size: 18px;
            color: #09323a;
            justify-content: center;
          }
          .column {
            padding-top: 40px;
            margin-left: 20px;
            display: flex;
            width: calc(35%);
            justify-content: center;
            align-items: center;
          }
        }
        .headline {
          font-size: 22px;
        }

        .subheadline2 {
          font-size: 14px;
          margin-top: 20px;
          margin-bottom: 10px;
          width: 100%;
          color: #09323a80;
          font-size: 16px;
          font-weight: 500;
          line-height: 29px;
        }
        .bottom {
          .blueButton {
            margin: 30px 0 30px 0;
          }
          .whiteButton {
            margin-top: 10px;
            margin-bottom: 40px;
            border: 0;
            font-size: 14px;
            text-align: center;
            width: fit-content;
            padding: 0 30px;
            min-width: 180px;
            border: 1px solid rgba(223, 228, 229, 0.658824);
          }
        }
      }
    }
    .text {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      margin: 0 15px;
      .headline {
        margin-bottom: 10px;
        width: 100%;
        color: #09323a;
        font-size: 18px;
        font-weight: bold;
        line-height: 29px;
      }
      .subheadline {
        margin-bottom: 10px;
        width: 100%;
        color: #09323a80;
        font-size: 16px;
        font-weight: 500;
        line-height: 29px;
      }
      span {
        font-weight: 600;
        color: #fd3f67;
      }
      .bottom {
        width: 100%;
        display: flex;
        justify-content: center;
        .blueButton {
          margin-top: 20px;
          margin-right: 120px;
          border: 0;
          text-align: center;
          width: fit-content;
          padding: 0 40px;
          min-width: 180px;
        }
      }
    }
    .close {
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
    }
    .image {
      margin: -80px 25px -5px 10px;
      height: 61px;
      width: auto;
    }
  }
}

#features.aptitude-test .plane-wrapper {
  .plane-inner {
    width: 35%;
    align-items: center;
    justify-content: center;
    .img {
      min-width: 200px;
      margin: 30px 0;
      width: 80%;
      object-fit: cover;
      border-radius: 15px;
    }
  }
}

#features.paragraph-tool .plane-wrapper {
  .plane-inner {
    width: 35%;
    align-items: center;
    justify-content: center;
    .img {
      width: 80%;
      object-fit: cover;
      border-radius: 15px;
    }
  }
}

.freques {
  margin: 0 0 100px 0;
  .title {
    margin-bottom: 40px;
  }
}

.test {
  .modal {
    box-shadow: 4px 25px 45px 0 rgba(112, 135, 139, 0.2);
    // overflow:scroll;
    background: linear-gradient(180deg, #fff, #e8fcff);
    max-width: 1000px;
    width: calc(100% - 80px);
    max-height: calc(100% - 80px);
    height: fit-content;
    margin: auto;
    padding: 70px 10vw 70px 10vw;
    border-radius: 20px;
    border: 1px solid #09323a20;
    color: #09323a;
    line-height: 1.8;
    font-size: 18px;
    font-weight: 500;
    .close {
      position: absolute;
      width: 30px;
      height: 30px;
      border-radius: 20px;
      border: 1px solid #e8eced;
      background: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin-top: -40px;
      margin-left: 752px;
    }
    .instructions {
      margin: -20px;
      position: relative;
      .title {
        margin: 0;
        width: 100%;
        height: fit-content;
        line-height: 1.8;
        font-size: 22px;
        font-weight: 900;
      }
      p {
        white-space-collapse: preserve-breaks;
      }
      .blueButton {
        width: 200px;
        margin: 40px auto 0px auto;
        font-size: 18px;
      }
      .close {
        position: absolute;
        top: 20px;
        right: -20px;
      }
    }
    .result {
      display: flex;
      width: 100%;
      justify-content: center;
    }
    &.phone {
      min-height: 400px;
    }
    &.results {
      padding: 40px 40px 30px 40px;
      max-height: calc(100vh - 40px);
      max-width: 1000px;
      //overflow-y: auto;
      .results {
        //padding: 0 70px 20px 70px;
        position: relative;
        flex-direction: column;
        display: flex;
        max-width: calc(100vw - 140px);
        .main {
          display: flex;
          flex-direction: row;
          
          .column {
            margin: 0px;
            width: 45%;
            height: 420px;
            overflow-y: auto;
            overflow-x: hidden;
            &.left{
              display: flex;
              justify-content: center;
            }
            &.right {
              width: 55%;
              padding: 0 0 0px 15px;
              height: 400px;
              &::-webkit-scrollbar {
                width: 0px;
              }
            }
            :last-child{
              margin-bottom: 15px;
            }
            .summary {
              margin-top: 10px;
              font-size: 15px;
              line-height: 1.6;
              width: 90%;
            }
            .block {
              margin: 0;
              width: 100%;
              height: fit-content;
              line-height: 1.8;
              font-size: 18px;
              font-weight: 600;
            }
            .chart {
              width: 400px; // !important;
              height: 300px; // !important;
              position: relative;
              margin: 25px 0 0 0;
              display: flex;
            }
            p {
              white-space-collapse: preserve-breaks;
              font-size: 14px;
              line-height: 1.5;
              width: 90%;
            }
          }
        }
        .close {
          position: absolute;
          top: 0px;
          right: -40px;
        }
        .blueButton {
          width: 200px;
          margin: 10px auto 30px auto;
          font-size: 15px;
        }
        .scores {
          font-size: 15px;
          table-layout: fixed;
          width: calc(50%);
          margin: auto;
          margin-top: 5px;
          text-align: center;
          border-collapse: collapse;
          background-color: #fff;
          box-shadow: 4px 25px 45px 0 rgba(112, 135, 139, 0.2);
          tbody tr:nth-child(odd) {
            background-color: #f2f2f2;
            td span {
              font-size: 7px;
              margin-left: 2px;
            }
          }
        }
        .improve {
          margin: auto;
          margin-top: 20px;
          p {
            font-size: 16px;
          }
          a {
            color: #0fc8ef;
            text-decoration: underline;
          }
          &.phone {
            margin: 0;
            margin-top: 5px;
            p {
              font-size: 12px;
            }
          }
        }
        .faq {
          width: calc(100% - 40px);
          margin: 30px 10px -25px 0px;
          padding: 0px 15px 0px 15px;
          background-color: white;
          height: fit-content;
          position: relative;
          overflow: hidden;
          border-collapse: collapse;
          box-shadow: 4px 4px 20px 0px rgba(112, 135, 139, 0.1);
          .faq-heading {
            margin: 0 0 -20px 0;
            padding: 5px 0px;
            width: 100%;
            height: fit-content;
            line-height: 1.5;
            font-size: 15px;
            font-weight: 600;
            color: #09323a;
            &.test {
              white-space: nowrap;
            }
          }
          .faq-text {
            padding: 0 10px;
            margin: 20px 0 -5px 0;
            white-space-collapse: preserve-breaks;
            font-size: 14px;
            color: #09323a;
          }
          .label {
            display: block;
            position: relative;
            overflow: hidden;
            cursor: pointer;
            height: fit-content;
            margin: 0;
            border-bottom: 0px solid #09323a12;
          }
          .faq-arrow {
            top: 0px;
            right: 5px;
          }
        }
      }
    }
    position: relative;
    .close {
      position: absolute;
      top: 70px;
      right: 30px;
    }
    .block {
      margin: 0 0 40px 0;
      width: 100%;
      height: fit-content;
      line-height: 1;
      font-size: 16px;
      font-weight: 900;
    }
    .question {
      height: 70px;
    }
    .ans {
      .answers {
        display: flex;
        flex-direction: row;
        font-weight: 400;
        margin-top: 15px;
        margin-left: 15px;
        .select {
          display: flex;
          height: 30px;
          width: 30px;
          border-radius: 15px;
          border: 1.5px solid #09323a50;
          background-color: white;
          margin-right: 15px;
          margin-top: 0px;
          cursor: pointer;
          align-items: center;
          justify-content: center;
          >div{
            border-radius: 11px;
            cursor: pointer;
            background-color: white;
            height: 22px;
            width: 22px;
            &:hover {
              background-color: #0ac9ef99;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 800px) {
  .test {
    .modal {
      .close {
        top: 50px;
        right: 10px;
      }  
      &.results {
        .results {
          .close {
            top: 10px;
            right: -30px;
          }
            .column{
              &.right{
                height:500px;
              }
            }
        }
      }     
    }
  }
}
@media (max-width: 780px) {
  .banner {
    &.offer {
      &.test {   
        .text {
          max-width: 600px;
          .top { 
            .subheadline {            
             margin-right: -20px;
            }
            .column{
              width: 0;
            }
          }
        }
      }
    }
  }          
}
@media (max-width: 600px) {
  .paragraph-tool .paragraph,
  .paragraph-tool .inputParagraph {
    font-size: 20px;
    line-height: 1.5;
  }  
}
@media (max-width: 400px) {
  .paragraph-tool .paragraph,
  .paragraph-tool .inputParagraph {
    font-size: 16px;
    line-height: 1.2;
  }   
}
