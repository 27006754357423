.subnavigation {
  position: sticky;
  top: 85px;
  background: white;
  z-index: 4;
  padding: 0;
  max-width: 80vw;
  margin-left: 10vw;
  .categories {
    height: 80px;
    border-bottom: 1px solid rgba(223, 228, 229, 0.658824);
    width: 100%;
    display: flex;
    flex-direction: row;
    position: relative;
    .category {
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      cursor: pointer;
      border-bottom: 2px solid transparent;
      > span {
        margin-top: 10px !important;
        margin-bottom: 10px !important;
        font-weight: 500;
        font-style: normal;
        font-size: 15px;
        letter-spacing: 0;
        color: #47656b;
        margin-top: 16px;
        margin-bottom: 17px;
      }
      img {
        height: 20px;
      }
      &.active {
        background-image: linear-gradient(0deg, #f7f9fb 0%, #ffffff 100%);
        border-bottom: 2px solid #16daf4;
      }
      &:hover {
        background-image: linear-gradient(0deg, #f7f9fb 0%, #ffffff 100%);
        border-bottom: 2px solid #16daf4;
      }
    }
  }
}
.feature {
  z-index: 3;
  padding: 170px 10vw 0px 10vw;
  width: 100%;
  &.feat {
    &:nth-child(2n) {
      background-image: linear-gradient(225deg, #f7f9fb 0%, #ffffff 30%);
    }
    &:nth-child(2n + 1) {
      background-image: linear-gradient(135deg, #f7f9fb 0%, #ffffff 30%);
    }
  }
  .supratitle {
    text-align: center;
    font-size: 18px;
    opacity: 0.45;
    margin-bottom: 5px;
    font-weight: 600;
  }
  .title {
    margin-top: 0px;
    color: #09323a;
    font-size: 40px;
    font-weight: bold;
    font-style: normal;
    line-height: 54px;
    text-align: center;
  }
  .description {
    max-width: 600px;
    margin: 0 auto;
    color: #09323a73;
    line-height: 30px;
    letter-spacing: 0;
    font-size: 18px;
    text-align: center;
    font-weight: normal;
    font-style: normal;
  }
  .learnmore {
    margin-top: 40px;
    margin-bottom: 60px;
    text-decoration: none;
    display: flex;
    color: #90a3a6;
    cursor: pointer;
    margin: 10px 0;
    &.center {
      margin: 10px auto;
      width: fit-content;
    }
    img {
      margin-left: 10px;
      width: 15px;
    }
  }
  .benefits-feature {
    display: flex;
    justify-content: space-between;
    margin-top: 80px;
    margin-bottom: 40px;
    position: relative;
    align-items: center;
    .image.general {
      padding: 50px;
      img {
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 7px 10px 50px 0 rgb(206 213 220 / 50%);
        border-radius: 30px;
        display: flex;
        width: 100%;
      }
    }
    .points {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 40%;
      .description {
        text-align: left;
        width: 100%;
        &.replaces {
          display: flex;
          img {
            margin: 5px;
          }
        }
      }
      .blueButton {
        margin: 10px;
        width: 200px;
      }
    }
    > .image {
      width: 50%;
      position: relative;
      height: fit-content;
      box-shadow: 0 10px 80px 0 rgba(232, 236, 237, 0.6);
      border-radius: 25px;
      overflow: hidden;
      img {
        display: none;
        &.visible {
          display: block;
          border-radius: 15px;
        }
      }
    }
  }
}
.strengths {
  display: flex;
  flex-wrap: wrap;
  width: 80vw;
  margin: 80px 0;
  .strength {
    display: flex;
    flex-direction: column;
    width: 25%;
    padding: 10px;
    align-items: center;
    img {
      width: 25px;
      margin-bottom: 15px;
    }
  }
  p {
    margin: 0px 0px 10px 0;
    color: #09323a73;
    text-align: center;
    margin-top: 8px;
  }
  span {
    color: #09323abf;
    font-weight: 600;
    text-align: center;
  }
}
.feature .benefits-feature .points .description > span.replace {
  border-radius: 6px;
  color: #fff;
  letter-spacing: 0;
  line-height: 14px;
  align-items: center;
  display: flex;
  text-align: center;
  font-weight: 600;
  margin-left: 10px;
  font-style: normal;
  margin-left: 5px;
  font-size: 12px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0px 14px 0px;
}
.feature .benefits-feature .points .description > span.replace:nth-child(0) {
  background: #05d6a2;
}
.feature .benefits-feature .points .description > span.replace:nth-child(1) {
  background: #fd3f67;
}
.feature .benefits-feature .points .description > span.replace:nth-child(2) {
  background: #fcb504;
}
.feature .benefits-feature .points .description > span.replace:nth-child(3) {
  background: #0ac9ef;
}
.feature .benefits-feature .points .description span,
.strengths span {
  color: #09323abf;
  font-weight: 600;
}
.feature .benefits-feature .points .description p {
  margin: 0px 0px 10px 0;
  color: #09323a73;
}
.feature .video {
  width: 100%;
}
.feature .benefits-feature .listbox {
  display: flex;
  flex-direction: column;
  width: calc(40% - 150px);
  > .info {
    position: relative;
    cursor: pointer;
    border-bottom: 1px #cccbcb solid;
    border-radius: 10px;
    padding: 30px;
    transition: 0.4s all ease-in-out;
    background-color: #fff;
    img {
      position: absolute;
      right: 10px;
      top: 32px;
      rotate: 270deg;
    }
    p {
      font-weight: 600;
      margin: 0 auto;
      color: #09323a73;
      line-height: 30px;
      letter-spacing: 0;
      font-size: 15px;
      text-align: left;
      font-weight: normal;
      font-style: normal;
    }
    span {
      font-weight: 600;
    }
  }
}

.feature .benefits-feature::before {
  background-size: 30%;
}
.feature .benefits-feature.red::before {
  content: '';
  position: absolute;
  top: -5px;
  left: -50px;
  z-index: -1;
  width: calc(100% + 50px);
  height: calc(100% + 20px);
  background-image: url(/images/features/dots-red.svg);
}
.feature .benefits-feature .image::before,
#features .plane::before {
  content: '';
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
}

.feature .benefits-feature.red .image::before,
#features .plane.red::before {
  bottom: -80px;
  left: -160px;
  opacity: 0.2;
  background-image: url(/images/features/blob-red.svg);
}
.feature .benefits-feature.blue .image::before,
#features .plane.blue::before {
  bottom: -80px;
  right: -60px;
  opacity: 0.2;
  background-image: url(/images/features/blob-blue.svg);
}
.feature .benefits-feature.yellow .image::before,
#features .plane.yellow::before {
  bottom: -80px;
  left: -160px;
  opacity: 0.2;
  background-image: url(/images/features/blob-yellow.svg);
}
.feature .benefits-feature.green .image::before,
#features .plane.green::before {
  bottom: -80px;
  right: -160px;
  opacity: 0.2;
  background-image: url(/images/features/blob-green.svg);
}

.feature .benefits-feature.green .image::before,
#features .plane.green::before {
  content: '';
  position: absolute;
  bottom: -200px;
  right: -100px;
  z-index: -1;
  opacity: 0.2;
  width: 100%;
  height: 100%;
  background-image: url(/images/features/blob-green.svg);
}
.feature .benefits-feature.blue::before {
  content: '';
  position: absolute;
  top: -5px;
  left: -50px;
  z-index: -1;
  width: calc(100% + 50px);
  height: calc(100% + 20px);
  background-image: url(/images/features/dots-blue.svg);
}
.feature .benefits-feature.yellow::before {
  content: '';
  position: absolute;
  top: -5px;
  left: -50px;
  z-index: -1;
  width: calc(100% + 50px);
  height: calc(100% + 20px);
  background-image: url(/images/features/dots-yellow.svg);
}
.feature .benefits-feature.green::before {
  content: '';
  position: absolute;
  top: -5px;
  left: -50px;
  z-index: -1;
  width: calc(100% + 50px);
  height: calc(100% + 20px);
  background-image: url(/images/features/dots-green.svg);
}
#features {
  overflow: hidden;
  max-width: 1250px;
  margin: auto;
}
#features .plane-wrapper {
  min-height: 40vh;
  width: 100%;
  margin: 100px 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  &:nth-child(even) .plane-inner {
    order: 2;
  }
  &:nth-child(even) .plane-title {
    order: 1;
  }
  &:nth-child(odd) .plane-inner {
    order: 1;
  }
  &:nth-child(odd) .plane-title {
    order: 2;
  }

  .supratitle {
    text-align: center;
    font-size: 18px;
    opacity: 0.45;
    margin-bottom: 5px;
    font-weight: 600;
  }
  .title {
    margin-top: 0px;
    color: #09323a;
    font-size: 30px;
    font-weight: bold;
    font-style: normal;
    line-height: 42px;
    text-align: left;
  }
  .description {
    max-width: 600px;
    color: #09323a73;
    line-height: 30px;
    letter-spacing: 0;
    font-size: 15px;
    text-align: left;
    font-weight: normal;
    font-style: normal;
  }
}

#features .plane-wrapper > img {
  max-width: 20%;
}

#features .plane-wrapper .plane-title {
  max-width: 380px;
}
#features .plane-inner {
  width: calc(50% - 40px);
  /*margin: 0px 20px;*/
  max-width: 460px;
  height: calc(100% - 0px);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
  }
}
#features .plane-title {
  color: black;
  height: 80%;
  font-size: 2em;
  font-weight: 700;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
}
#features .plane-title img {
  width: 30px;
  padding: 26px;
  border: 1px solid rgba(223, 228, 229, 0.658824);
  border-radius: 22px;
}

#features .plane {
  z-index: 2;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  img.img {
    max-height: 600px;
    max-width: 500px;
    height: auto;
    width: 100%;
  }
}
.bannered {
  width: 80%;
  max-width: 1250px;
  margin: auto;
}
.highlights {
  display: flex;
  flex-wrap: wrap;
  width: 80vw;
  margin: 80px auto;
  max-width: 1250px;

  .highlight {
    display: flex;
    flex-direction: column;
    width: 33.33%;
    padding: 0 30px;
    align-items: center;
    img {
      width: 25px;
      margin-bottom: 15px;
    }
  }
  p {
    margin: 0px 0px 10px 0;
    color: #09323a73;
    text-align: center;
    margin-top: 8px;
  }
  span {
    color: #09323abf;
    font-weight: 600;
    text-align: center;
  }
}

@media only screen and (max-width: 980px) {
  .feature .benefits-feature {
    flex-direction: column;
  }
  .feature .benefits-feature > div {
    width: 90% !important;
  }
  .feature .benefits-feature .points {
    padding-left: 0 !important;
    padding-right: 0 !important;
    order: 1;
    margin-top: 20px;
  }
  .feature .benefits-feature .listbox > .info.expand {
    height: 80px;
  }
  .feature .benefits-feature > div.listbox {
    margin-bottom: 40px;
  }
}
@media only screen and (max-width: 650px) {
  .subnavigation {
    max-width: 100vw;
    margin-left: 0vw;
  }
  #features .plane-wrapper {
    flex-direction: column;
    .plane-inner {
      width: 90%;
      max-width: 100%;
      order: 2;
    }
    .plane-title {
      order: 1;
      width: 90%;
      max-width: 100%;
    }
  }
  .highlights {
    flex-direction: column;
    margin: 0px auto;
    .highlight {
      width: 100%;
    }
  }
}
@media only screen and (max-width: 550px) {
  .strengths {
    align-items: center;
    justify-content: center;

    .strength {
      min-width: 150px;
    }
  }
}
@media only screen and (max-width: 380px) {
  .subnavigation {
    display: none;
  }
  .import.intro {
    margin-bottom: 0px;
  }
}
