.pricingintro {
  margin: 180px 0 100px 0;
  padding-left: 10px;
  background-image: url(/images/LeftBack.svg), url(/images/RightBack.svg);
  background-position:
    -15% 20%,
    115% 20%;
  background-repeat: no-repeat, no-repeat;
}

.pricingintro .title {
  color: #09323a;
  margin-bottom: 0px;
  margin-top: 0px;
  width: 90%;
  max-width: 550px;
  margin: auto;
  text-align: center;
  font-size: 40px;
  line-height: 50px;
}

.pricingintro .description {
  width: 720px;
  color: #09323a73;
  margin: auto;
  font-weight: normal;
  text-align: center;
  margin-top: 50px;
  line-height: 30px;
  font-size: 18px;
  max-width: 80vw;
}

.price {
  display: flex;
  flex-wrap: wrap;
  width: fit-content;
  max-width: 100%;
  margin: 0 auto;
}

.price .plan {
  position: relative;
  margin: 75px 10px 0;
  width: 270px;
  border-radius: 15px;
  background: white;
  box-shadow: 5px 50px 70px 10px rgba(71, 101, 107, 0.04);
  display: flex;
  align-items: center;
  flex-direction: column;
}

.plan {
  padding-bottom: 0px;
}

.plan.popular {
  border: 2px solid #c26edc;
  /* background: #D8D8D880 ; */
}

.plan .popular {
  position: absolute;
  top: -1px;
  color: white;
  width: calc(100% + 4px);
  background-color: #c26edc;
  line-height: 29px;
  text-align: center;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
}

.plan .title {
  color: #09323a;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  margin-top: 60px;
  margin-bottom: 24px;
  margin-left: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.plan .title img {
  margin: 0 10px 3px 0;
  width: 19px;
}

.plan .blueButton {
  margin-top: 43px;
  margin-bottom: 59px;
}

.plan .value {
  position: relative;
  margin-top: 24px;
  /*color: red;*/
  font-weight: 500;
  font-weight: 400;
  display: flex;
}

.plan .value .num {
  font-size: 70px;
  letter-spacing: 0px;
}

.plan .value .currency {
  font-size: 20px;
  top: 0;
  left: -15px;
  position: absolute;
}

.plan .discount {
  position: absolute;
  width: 80%;
  height: 40px;
  color: #fff;
  font-size: 20px;
  background: #c26edc;
  top: -40px;
  border-radius: 20px 20px 0 0;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
}

.plan .sales {
  position: relative;
  color: #09323a;
  font-weight: 500;
  font-weight: 400;
  position: absolute;
  top: 0px;
  right: -15px;
}

.plan .line {
  width: 270px;
  height: 1px;
  background: #d8d8d850;
}

.plan .list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 270px;
  padding: 0 30px;
  height: 56px;
  border-bottom: 1px solid #d8d8d850;
}

.plan .list.last {
  margin-bottom: 56px;
}

.plan .list img {
  margin-right: 15px;
  padding: 6px 5px;
  background: #0fc8ef;
  border-radius: 15px;
}

.plan .list span {
  margin-right: 5px;
}

.plan .whiteButton,
.plan .blueButton {
  width: 240px;
  margin-top: 10px;
  margin-bottom: 30px;
  text-decoration: none;
}

.plan .whiteButton:hover,
.plan .blueButton:hover {
  text-decoration: none;
}

.plan .whiteButton {
  color: #09323a;
}

.plan .blueButton {
  color: white;
}

.plan .type {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 25px 10px;
  width: 255px;
  height: 48px;
  border-radius: 10px;
  background: #f7f9fbcc;
}

.plan .type img {
  margin-right: 15px;
}

.allincluded {
  width: 100%;
  height: 48px;
  color: #09323a;
  font-size: 30px;
  font-weight: bold;
  line-height: 48px;
  text-align: center;
}

.contact {
  align-items: center;
}

.contact .description {
  margin-bottom: 30px;
}

.contact.blueButton {
  width: fit-content;
}

@media (max-width: 600px) {
  .selector {
    width: 345px;
  }

  .selector .top {
    width: 112px;
  }

  #selector.top.monthly {
    left: 5px;
  }

  #selector.top.yearly {
    left: 116px;
  }

  #selector.top.lifetime {
    left: 228px;
  }

  .price {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .price .plan {
    width: 270px;
  }
}
