.edbanner {
  position: fixed;
  top: 85px;
  width: 100vw;
  height: 30px;
  z-index: 0;
  background: #c26edc;
  /*background: linear-gradient(90deg,#fcb504,#fcb504 25%,#fd3f67 0,#fd3f67 50%,#0ac9ef 0,#0ac9ef 75%,#05d6a2 0,#05d6a2);*/
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
}
.edbanner .whiteButton {
  min-height: 35px;
  max-height: 35px;
  margin-left: 20px;
}
.edbanner span {
  color: white;
  font-size: 16px;
  font-weight: 600;
}

.freques {
  margin: 50px 0;
}
.clients .title,
.howtogetit .title,
.freques .title {
  color: #09323a;
  font-size: 32px;
  font-weight: bold;
  line-height: 42px;
  text-align: center;
  margin: auto;
  margin-bottom: 15px;
  max-width: 900px;
}
.clients .description,
.howtogetit .description {
  width: 60%;
  color: #09323a99;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  text-align: center;
  max-width: 100%;
}
.freques .description {
  color: #09323a99;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  text-align: center;
  max-width: 100%;
  margin-bottom: 60px;
}
.freques .description a {
  text-decoration: underline;
}
.import .imports {
  margin: 10px 0;
}
.import .imports img {
  margin: 0 10px;
}
.howtogetit {
  display: flex;
  flex-direction: column;
  width: 80vw;
  margin-left: 10vw;
  margin-top: 80px;
  margin-bottom: 80px;
}
.howtogetit .list span {
  font-size: 20px;
  color: rgba(9, 50, 58, 0.6);
  line-height: 28px;
  letter-spacing: 0;
  margin: 0;
}
.howtogetit .list .step {
  display: flex;
  align-items: center;
  margin: 15px 0;
}
.howtogetit .list .step img {
  width: 44px;
  height: auto;
  margin-right: 15px;
  background: #f9b400;
  padding: 8px;
  border-radius: 10px;
}

.features {
  display: flex;
  flex-wrap: wrap;
  width: 80vw;
  margin-left: 10vw;
  margin-top: 80px;
  margin-bottom: 80px;
  justify-content: space-around;
}
.features .feature {
  display: flex;
  flex-direction: column;
  width: calc(33.3% - 40px);
  align-items: center;
  margin: 40px 0;
  min-width: 210px;
  padding: 0 20px;
}
.features .feature .title {
  color: #09323a;
  font-size: 20px;
  font-weight: bold;
  line-height: 29px;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;
}
.features .feature .description {
  width: 60%;
  color: #09323a99;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  text-align: center;
}

.featuresed {
  background: linear-gradient(0deg, #ffffff, #e8fcff);
  /*background: linear-gradient(0deg, #FFFFFF80, #F0F6F780);*/
  margin-top: 100px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  position: relative;
  justify-content: center;
}
.featuresed .blueButton {
  position: absolute;
  margin-top: 180px;
  max-height: 64px;
  font-size: 15px;
  border-radius: 60px;
}
.featuresed .titlehead {
  color: #09323a;
  font-weight: 800;
  max-width: 680px;
  line-height: 25px;
  text-align: center;
  position: absolute;
}
.featuresed h2::before {
  content: '';
  z-index: 0;
  position: absolute;
  height: 4px;
  bottom: -30px;
  left: 0px;
  margin: auto;
  right: 0px;
  background: linear-gradient(
    90deg,
    #fcb504 0%,
    #fcb504 25%,
    #fd3f67 25%,
    #fd3f67 50%,
    #0ac9ef 50%,
    #0ac9ef 75%,
    #05d6a2 75%,
    #05d6a2 100%
  );
}
.featuresed h2 {
  font-size: 24px;
}
.featuresed img {
  width: 60%;
  margin-top: 20px;
  height: auto;
}
.featuresed h4 {
  margin: auto;
  width: 600px;
  font-size: 15px;
}
.howtogetit {
}

.selector {
  margin: auto;
  width: 308px;
  height: 46px;
  background: #eff3f7;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: 74px;
}
.selector .top {
  width: 153px;
  box-shadow: 0 3px 6px 0 rgba(71, 101, 107, 0.12);
  border-radius: 25px;
  background: #fff;
  height: 80%;
  top: 10%;
  position: absolute;
  bottom: 0;
  transition: all 0.5s ease;
}
#selector.top.monthly {
  left: 5px;
}
#selector.top.yearly {
  left: 151px;
}
#selector.top.lifetime {
  left: 303px;
}
.selector .tab {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 38px;
  cursor: pointer;
  z-index: 2;
}
.selector .tab p {
  margin: 0;
  color: #fa3b63;
  text-align: center;
}
.selector .tab .save {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0;
  color: white;
  text-align: center;
  background-color: #c26edc;
  width: 115px;
}

.selector .tab span {
  padding: 0 27px;
  font-weight: 600;
  font-style: normal;
  font-size: 15px;
  color: #47656b;
  letter-spacing: 0;
  text-align: center;
}
.selector .tab {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 100%;
  width: 208px;
}
.selector .tab span {
  font-weight: 600;
  font-style: normal;
  font-size: 16px;
  color: #47656b;
  letter-spacing: 0;
}
/*
  .price {
    display: flex;
    width: 890px;
    max-width: 100%;
    margin: auto;
  }
  .price .plan {
    position: relative;
    margin: 74px 35px 0 35px;
    width: 410px;
    border-radius: 15px;
    background: #ffffff;
    box-shadow: 5px 50px 70px 10px rgba(71,101,107,0.04);
    display: flex;
    align-items: center;
    flex-direction: column;
    height: fit-content;
  }
  .plan .title{
    color: #09323a;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    margin-top: 60px;
    margin-bottom: 24px;
  }
  .plan .blueButton{
    margin-top: 43px;
    margin-bottom: 59px;
  }
  .plan .value{
    display: flex;
    font-weight: 500;
    font-weight: 400;
  }
  */
.plan .limited {
  margin-top: 40px;
  background: #c26edc;
  font-weight: bold;
  font-size: 600;
  width: 100%;
  text-align: center;
  color: white;
  padding: 10px;
  position: relative;
}
.limited:before,
.limited:after {
  content: '';
  position: absolute;
  display: block;
  bottom: -10px;
  border: 19px solid #a04abb;
  z-index: -1;
}
.limited:before {
  left: -2em;
  border-right-width: 1.5em;
  border-left-color: transparent;
}
.limited:after {
  right: -2em;
  border-left-width: 1.5em;
  border-right-color: transparent;
}
.plan .value .new {
  position: relative;
  color: red;
  margin: 0 20px;
}
.plan .value .new .num {
  font-size: 70px;
  letter-spacing: 0px;
}
.plan .value .new .currency {
  font-size: 30px;
  top: -0;
  left: -25px;
  position: absolute;
}
.plan .value .old {
  position: relative;
  color: #09323a;
  font-weight: 500;
  font-weight: 400;
}
.plan .value .old .num {
  font-size: 20px;
  letter-spacing: 0px;
  position: relative;
}
.plan .value .old .num::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  border-top: 2px solid #09323a;
  transform: rotate(-20deg);
}

.plan .value .old .currency {
  font-size: 12px;
  top: -0;
  left: -10px;
  position: absolute;
}

.plan .discount {
  position: absolute;
  width: 80%;
  height: 40px;
  font-size: 20px;
  background: #c26edc;
  top: -40px;
  border-radius: 20px 20px 0 0;
  color: white;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ribbon {
  margin: 0;
  padding: 0;
  background: #fd3f67;
  color: white;
  padding: 10px 0;
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(30%) translateY(0%) rotate(45deg);
  transform-origin: top left;
}
.ribbon:before,
.ribbon:after {
  content: '';
  position: absolute;
  top: 0;
  margin: 0 -1px; /* tweak */
  width: 100%;
  height: 100%;
  background: #fd3f67;
}
.ribbon:before {
  right: 100%;
}

.ribbon:after {
  left: 100%;
}

.basicinfo {
  background: white;
  margin: 100px 0;
  width: 100%;
  padding: 40px 0;
}

.faq {
  width: 80vw;
  margin: 0 10vw;
  background: white;
  position: relative;
  overflow: hidden;
  border-radius: 20px;
}
.faq-heading {
  font-size: 20px;
  font-weight: 600;
  text-indent: 0;
  -webkit-transition: text-indent 0.2s;
  padding: 0 40px;
  color: #09323a80;
  padding-right: 53px;
}

.faq-text {
  padding: 0 40px;
  font-weight: 400;
  font-size: 20px;
  text-indent: 0px;
  color: #09323a80;
  width: 95%;
  margin-bottom: 30px;
}

.faq label {
  display: block;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  height: fit-content;
  padding-top: 1px;
  border-bottom: 1px solid #09323a12;
  background-color: #ffffff;
}

.faq input[type='checkbox'] {
  display: none;
}

.faq .faq-arrow {
  width: 5px;
  height: 5px;
  transition: -webkit-transform 0.8s;
  transition: transform 0.8s;
  transition:
    transform 0.8s,
    -webkit-transform 0.8s;
  -webkit-transition-timing-function: cubic-bezier(0.68, 0.265, 1.55);
  border-top: 2px solid rgba(0, 0, 0, 0.33);
  border-right: 2px solid rgba(0, 0, 0, 0.33);
  float: right;
  position: relative;
  top: -30px;
  right: 27px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.faq input[type='checkbox']:checked + label > .faq-arrow {
  transition: -webkit-transform 0.8s;
  transition: transform 0.8s;
  transition:
    transform 0.8s,
    -webkit-transform 0.8s;
  -webkit-transition-timing-function: cubic-bezier(0.68, 0.265, 1.55);
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}
.faq input[type='checkbox']:not(:checked) + label .faq-text {
  display: none;
}
.faq input[type='checkbox']:checked + label {
  display: block;
  background: rgba(255, 255, 255, 255) !important;
  color: #4f7351;
  transition: height 0.8s;
  -webkit-transition-timing-function: cubic-bezier(0.68, 0.265, 1.55);
}

.faq input[type='checkbox']:not(:checked) + label {
  display: block;
  transition: height 0.8s;
  height: fit-content;
  -webkit-transition-timing-function: cubic-bezier(0.68, 0.265, 1.55);
}

::-webkit-scrollbar {
  /*display: none;*/
}
@media (max-width: 930px) {
  .featuresed {
    display: none;
  }
}

@media (max-width: 930px) {
  .faq-heading {
    font-size: 16px;
    line-height: 20px;
  }
  .faq-text {
    font-size: 16px;
    line-height: 20px;
  }
  .reviews > h4 {
    padding: 0;
  }
}
@media (max-width: 630px) {
  header {
    margin: 0;
    width: 100%;
  }
  .introguides .title {
    width: 50%;
  }
  .introguides .description {
    display: none;
  }
}
@media (max-width: 580px) {
  .edbanner {
    height: 50px;
    text-align: center;
  }
}
@media (max-width: 550px) {
  .introguides .title {
    font-size: 20px;
  }
}
