/*
@font-face {
  font-family: 'Gilroy';
  font-weight: 500;
  font-style: normal;
  src: url('/fonts/Gilroy-Medium/font.woff2') format('woff2'), url('/fonts/Gilroy-Medium/font.woff') format('woff');
}
@font-face {
  font-family: 'Gilroy';
  font-weight: bold;
  font-style: normal;
  src: url('/fonts/Gilroy-Bold/font.woff2') format('woff2'), url('/fonts/Gilroy-Bold/font.woff') format('woff');
}
@font-face {
  font-family: 'Gilroy';
  font-weight: 600;
  font-style: normal;
  src: url('/fonts/Gilroy-SemiBold/font.woff2') format('woff2'), url('/fonts/Gilroy-SemiBold/font.woff') format('woff');
}
@font-face {
  font-family: 'Gilroy';
  font-weight: 400;
  font-style: normal;
  src: url('/fonts/Gilroy-Regular/font.woff2') format('woff2'), url('/fonts/Gilroy-Regular/font.woff') format('woff');
}
*/
html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Gilroy', Arial, sans-serif;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.lastarticles {
  display: flex;
  margin-top: 165px;
  margin-bottom: 100px;
  max-width: 80vw;
  width: 80vw;
  margin-left: 10vw;
  margin-right: 10vw;
  display: flex;
  justify-content: center;
}

.lastarticles .leftsection {
  width: 50%;
  height: 500px;
  margin-right: 39px;
  position: relative;
  background-size: cover;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  background-position: center;
}

.lastarticles .leftsection img {
  width: 100%;
  height: 70%;
  object-fit: cover;
}

.lastarticles .leftsection .rectangle {
  position: absolute;
  width: calc(100% - 50px);
  left: 0;
  right: 0;
  bottom: 25px;
  padding: 25px;
  margin: auto;
  background: #09323a;
  border-radius: 6px;
}

.lastarticles .leftsection .rectangle > .title {
  color: white;
  font-size: 24px;
  font-weight: bold;
  line-height: 34px;
  width: 100%;
  text-transform: capitalize;
}

.lastarticles .leftsection .rectangle .subtitle {
  margin-top: 15px;
  margin-bottom: 0px;
  display: flex;
}

.lastarticles .leftsection .rectangle .subtitle > span {
  color: rgba(255, 255, 255, 0.404);
}

.lastarticles .leftsection .rectangle .subtitle > span span {
  color: rgba(255, 255, 255);
}

.lastarticles .rightsection {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-left: 39px;
  height: 500px;
}

.lastarticles .rightsection .single {
  margin-bottom: 35px;
  border-bottom: 1px solid #09323a16;
}

.lastarticles .rightsection .single .title {
  font-weight: bold;
  font-size: 36px;
  color: #09323a;
  line-height: 40px;
  margin-bottom: 30px;
  text-transform: capitalize;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.lastarticles .rightsection .single .resume {
  font-weight: 500;
  font-size: 15px;
  color: #09323a93;
  margin-top: 20px;
  line-height: 20px;
  margin-bottom: 25px;
}

.similararticles {
  display: flex;
  flex-direction: column;
  max-width: 80%;
  width: 100%;
  margin-left: 10%;
  margin-right: 10%;
  display: flex;
  justify-content: center;
  position: relative;
}

.similararticles .single.empty {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: default;
}

.similararticles .single.empty .title {
  cursor: default;
  text-decoration: none;
}

.similararticles .single.empty span {
  width: 300px;
  text-align: center;
  opacity: 0.4;
  margin-top: 5px;
  margin-bottom: 20px;
  line-height: 20px;
}

.similararticles .single.empty img {
  width: auto;
  height: 130px;
}

.similararticles.nobanner .articles .single:last-child {
  margin-right: 0;
}

.similararticles .sectiontop {
  position: relative;
  border-bottom: 2px solid #09323a16;
  display: flex;
  justify-content: space-between;
  order: -2;
}

.similararticles .sectiontop.startups::before {
  content: '';
  position: absolute;
  bottom: -6px;
  height: 6px;
  width: 20%;
  background-color: #fcb911;
}

.similararticles .sectiontop.remotework::before {
  content: '';
  position: absolute;
  bottom: -6px;
  height: 6px;
  width: 20%;
  background-color: #12d8a7;
}

.similararticles .sectiontop.edworking::before {
  content: '';
  position: absolute;
  bottom: -6px;
  height: 6px;
  width: 20%;
  background-color: #16ccf0;
}

.title {
  margin: 0;
}

.similararticles .sectiontop span {
  display: flex;
  text-transform: capitalize;
  justify-content: space-between;
  padding-bottom: 38px;
}

.single:hover .title {
  text-decoration: underline;
}

.similararticles .sectiontop a {
  display: flex;
  justify-content: space-between;
  padding-bottom: 38px;

  &:hover {
    text-decoration: underline;
    color: #0fc8ef;

    span {
      color: #0fc8ef;
    }
  }
}

.similararticles .sectiontop span.title {
  font-weight: bold;
  font-size: 36px;
  color: #09323a;

  &.bread {
    font-size: 24px;
    color: gray;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 8px;
  }
}

.similararticles .sectiontop a.more {
  font-weight: bold;
  font-size: 14px;
  color: #09323a;
  align-items: center;
}

.similararticles .articles {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 55px;
  margin-bottom: 80px;
  position: relative;
}

.similararticles .articles .left:hover,
.similararticles .articles .right:hover {
  opacity: 1;
}

.similararticles .left {
  position: absolute;
  rotate: 180deg;
  left: -60px;
  height: 50px;
  margin: auto;
  opacity: 0.6;
  top: 0;
  bottom: 0;
  cursor: pointer;
}

.similararticles .right {
  position: absolute;
  right: -60px;
  height: 50px;
  opacity: 0.6;
  margin: auto;
  top: 0;
  bottom: 0;
  cursor: pointer;
}

.similararticles .articles .single.blog {
  display: flex;
  flex-direction: column;
  margin-right: 60px;
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: 0px;
  flex-basis: calc(25% - 45px);
  min-width: 150px;
}

.similararticles .articles .single .a {
  width: 100%;
}

.similararticles .articles .single .subtitle {
  margin-top: 20px;
  display: flex;
}

.similararticles .articles .single .subtitle .created {
  margin-right: 0px;
}

.similararticles .articles .single .cover {
  height: 170px;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 30px;

  &.text {
    display: flex;
    justify-content: center;
    background-image: url('/images/PodcastBackground.png');
    background-size: contain;

    &:hover {
      img {
        display: flex;
      }

      span {
        display: none;
      }
    }

    img {
      display: none;
    }

    span {
      display: flex;
      align-items: center;
      line-height: 27px;
      text-align: center;
      text-transform: capitalize;
      padding: 20px;
      font-weight: bold;
    }
  }
}

.similararticles .articles .single .title {
  font-weight: bold;
  font-size: 18px;
  color: #09323a;
  line-height: 20px;
  text-transform: capitalize;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.similararticles .articles .single .resume {
  font-weight: 500;
  font-size: 15px;
  color: #09323a93;
  margin-top: 20px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  /* number of lines to show */
  line-clamp: 4;
  -webkit-box-orient: vertical;
}

.pagination {
  min-width: 100%;
  text-align: center;

  span,
  a {
    cursor: pointer;
    color: #09323a;
    font-size: 18px;
    font-weight: 600;
    padding: 15px;

    &:hover {
      color: #02bcea;
      background-color: #f7f9fb;
    }
  }
}

.article.blog,
.article.workspace {
  display: flex;
  flex-direction: column;
  max-width: 700px;
  width: 85vw;
  margin-left: 20vw;
  margin-right: 20vw;
  margin: auto;
  display: flex;
  justify-content: center;
  padding-bottom: 80px;
  align-items: center;
}

.article.workspace .embebbed {
  display: flex;
  height: 100%;
  width: 100%;
}

.article.workspace .embedded iframe {
  flex-grow: 1;
  border: none;
}

.article.workspace > img {
  width: 100%;
}

.article.workspace .ql-tooltip,
.article.workspace .ql-clipboard {
  display: none;
}

.article.workspace .ql-video {
  width: 100%;
  height: 300px;
}

.article.blog {
  display: flex;
  flex-direction: column;
  max-width: 80vw;
  width: 80vw;
  margin-left: 60px;
  margin-right: 60px;
  display: flex;
  justify-content: center;
  padding-bottom: 80px;
  align-items: center;

  .body > iframe {
    width: 100%;
    height: 325px;
  }

  .index {
    position: sticky;
    top: 120px;
    max-width: 20vw;
    left: 10vw;
    margin: 0;
  }

  .banner {
    width: calc(120%);

    .image {
      margin: -20px 15px -5px 0;
    }

    .blueButton {
      padding: 0 10px;
      margin: 0 20px;
    }

    .text {
      width: calc(100% - 300px);

      .headline {
        width: 100%;
      }

      .subheadline {
        width: 100%;
      }
    }
  }
}

.coverarticle {
  display: flex;
  max-height: 300px;
  object-fit: cover;
  margin-bottom: 40px;
  width: 80vw;
  max-width: 1250px;
  border-radius: 20px;
  margin: 20px auto;
}

.coverarticle.default {
  transform: rotate(180deg);
}

.blogarticle {
  margin: auto;
  display: flex;
  max-width: 1250px;
  padding: 10px 25px;

  .article {
    width: calc(100% - 120px);

    .faqsbox {
      width: 100%;
      background: white;
      position: relative;
      overflow: hidden;
      border-radius: 20px;
      border: 1px solid #09323a12;
      box-shadow: 0 10px 80px 0 rgba(232, 236, 237, 0.6);
      margin-top: 20px;
      h4 {
        border-top: 1px solid #09323a12;
        border-bottom: 1px solid #09323a12;
        cursor: pointer;
        padding: 15px 25px;
        margin: 0;
        font-size: 16px;
        font-weight: 600;
      }

      p,
      ul {
        max-height: 0px;
        margin: 0;
        font-size: 16px;
        cursor: default;
        opacity: 0;

        &.show {
          padding: 20px 40px;
          opacity: 1;
          max-height: none;
        }
      }
    }
  }

  .content {
    min-width: 300px;
    max-width: 300px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    margin: 0;
    margin-bottom: 0px;
    position: -webkit-sticky;
    position: sticky;
    top: 160px; //120px;
    border: 1px solid #09323a16;

    .title {
      display: flex;
      margin: 10px 40px 10px 40px;
      width: calc(100% - 80px);
      font-size: 18px;
      color: #09323a;
      border-bottom: 1px solid #09323a16;
      padding-bottom: 20px;
      padding-bottom: 20px;
      padding-top: 20px;
    }

    .elements {
      display: flex;
      flex-direction: column;
      width: 100%;
      cursor: pointer;
      font-size: 14px;
      padding: 0;

      .clickable {
        display: flex;
        align-items: center;
        margin: 0;
        width: 100%;

        .element {
          display: flex;
          flex-direction: column;
          padding: 0 40px;
          margin: 0 0 20px 0;
          width: calc(100%);
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
          font-weight: 600;
          line-height: 20px;
          color: #09323a;
          font-size: 16px;
          /*36px*/
          border-left: 3px solid transparent;

          &.selected {
            border-left: 3px solid #16ccf0;
            height: 80%;
          }
        }
      }
    }
  }
}

.article .cover {
  max-height: 300px;
  object-fit: cover;
  margin-bottom: 40px;
}

.article .cover.default {
  transform: rotate(180deg);
}

.article .quill {
  max-width: 700px;
  min-height: 90vh;
  width: 60vw;
  margin-left: 20vw;
  margin-right: 10vw;
  margin-left: 10vw;
}

.article .blueButton {
  width: 300px;
  color: white;
  margin: 40px auto;
}

.article .quill .ql-editor {
  overflow: visible;
  padding: 0;
}

.article p {
  font-size: 16px;
  line-height: 32px;
  color: rgba(9, 50, 58, 0.9);
  letter-spacing: 0;
  margin: 0;
  margin-top: 20px;
}

.article video {
  height: auto;
  width: 100%;
}

.article ul {
  margin-top: 24px;
}

.article li {
  font-size: 16px;
  color: rgba(9, 50, 58, 0.9);
  line-height: 28px;
  letter-spacing: 0;
  margin: 0;
}

.article pre {
  margin-bottom: 5px;
  margin-top: 5px;
  white-space: pre-wrap;
  border-radius: 3px;
  padding: 5px 10px;
}

.article pre.ql-syntax {
  background-color: #23241f;
  color: #f8f8f2;
  overflow: visible;
}

.article .quill .ql-container {
  font-family: 'Gilroy', Arial, sans-serif !important;
  font-size: 15px;
}
.article ._image_nstdf_261{
  width: 80% !important;
}
.article h1 {
  margin-top: 38px;
  font-size: 32px;
  line-height: 40px;
  color: #09323a;
  margin-bottom: 0;
}

.article h2 {
  margin-top: 34px;
  line-height: 32px;
  font-size: 28px;
  color: #09323a;
  margin-bottom: 0;
}

.article h3 {
  position: relative;
  width: 100%;
  margin-top: 30px;
  font-size: 24px;
  line-height: 26px;
  color: #09323a;
  margin-bottom: 0;
}

.article h4 {
  position: relative;
  width: 100%;
  margin-top: 26px;
  color: #09323a;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 0;
}

.article h2 {
  position: relative;

  &:before {
    position: absolute;
    left: -50px;
    top: 0;
    content: '';
    width: 100%;
    height: 100%;
  }

  &:hover .link {
    display: flex;
  }

  .link {
    display: none;
    cursor: pointer;
    position: absolute;
    left: -40px;
    height: 30px;
    width: auto;
    margin: 0;
    padding: 5px;
    border-radius: 15px;

    &:hover {
      display: flex;
      background: #f7f9fb;
    }
  }
}

.article a {
  color: #23c49b;
}

.article a:hover {
  text-decoration: underline;
}

.article .body {
  width: 60vw;
  max-width: 700px;
  display: flex;
  flex-direction: column;
}

.article img {
  width: 100%;
  border-radius: 10px;
  margin: 25px 0%;
}

.article h4::before {
  content: null;
  z-index: 1;
  position: absolute;
  width: 6px;
  top: 0px;
  bottom: 0px;
  left: -34px;
  background: linear-gradient(
    180deg,
    #fcb504 0%,
    #fcb504 25%,
    #fd3f67 25%,
    #fd3f67 50%,
    #0ac9ef 50%,
    #0ac9ef 75%,
    #05d6a2 75%,
    #05d6a2 100%
  );
}

.tryedworking {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  height: 300px;
  background: linear-gradient(0deg, #ffffff, #f0f6f7);
}

.tryedworking img {
  position: absolute;
  width: 60%;
}

.tryedworking span {
  z-index: 1;
  width: 368px;
  max-width: 100%;
  color: #09323a;
  font-size: 27px;
  font-weight: bold;
  line-height: 49px;
  text-align: center;
}

.tryedworking div {
  margin: 20px;
  z-index: 1;
}

/*
footer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  max-width: 100vw;
  min-height: 80px;
  max-width: 100vw;
  margin: 0;
  border-top: 1px solid rgba(223,228,229,.658824);
  padding: 0;
}
footer .contact {
  font-weight: 500;
  font-style: normal;
  font-size: 15px;
  color: #47656b8c;
  letter-spacing: 0;
}
footer .contact span {
  cursor: pointer;
  margin-left: 15px;
  color: #09323A;
  font-weight: 500;

}
footer .contact a {
  cursor: pointer;
  margin-left: 15px;
  text-decoration: none;
  color: #09323A;
  font-weight: 500;
}

footer .contact a:hover {
  color: #16DAf4;
}

footer .contact span:hover {
  color: #16DAf4;
}
footer .logo {
  height: 24px;
}
footer .social {
  font-weight: 500;
  font-style: normal;
  color: #47656B;
  font-size: 15px;
  letter-spacing: 0;
  display: flex;
  align-items: center;
}
footer .social img {
  cursor: pointer;
  border-radius: 14px;
  background: #ffffff;
  opacity: 1;
  border: 1px solid rgba(223,228,229,.658824);
  width: 46px;
  height: 46px;
  padding: 10px;
  margin: 5px;
}
footer .social span {
  margin-right: 20px;
}
footer .social img#dark {
  display: none;
  background: #2A3D42;
}
footer .social img#light {
  display: block;
  background: #FFFFFF;
  border: 1px solid #c4d0d3bf;
}
footer .social img:hover {
  background: #2A3D42;
  box-shadow: 2px 8px 10px 0 rgba(206,213,220,0.10);
}
*/
header.blog {
  top: 40px;
}

header.share::before {
  content: '';
  z-index: 0;
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 1px;
  background: linear-gradient(
    90deg,
    #fcb504 0%,
    #fcb504 25%,
    #fd3f67 25%,
    #fd3f67 50%,
    #0ac9ef 50%,
    #0ac9ef 75%,
    #05d6a2 75%,
    #05d6a2 100%
  );
}

header .logo {
  height: 25px;
  width: auto;
  position: absolute;
  left: 5px;
  cursor: pointer;
}

header .menu {
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
}

header .menu .signupsmall {
  display: none;
}

header .menu > div:hover {
  color: #16daf4;
}

/*
header .menu div.signup {
  color: #fff;
  background: #0FC8EF;
  border-radius: 22px;
  height: 44px;
  width: 142px;
  max-height: 44px;
  padding: 1px 0px 0px 0px;
  font-weight: 600;
  font-style: normal;
}
header .menu div.signup:hover {
  background: #02BBE2;
}*/
header .menu div.signupsmall {
  color: #fff;
  background: #0fc8ef;
  border-radius: 22px;
  height: 44px;
  width: 85px;
  max-height: 44px;
  padding: 1px 0px 0px 0px;
  font-weight: 600;
  font-style: normal;
}

header .menu div.signupsmall:hover {
  background: #02bbe2;
}

.unavailable {
  margin: auto;
  left: 0;
  right: 0;
  top: 100px;
  position: absolute;
  width: 400px;
  height: 50px;
  text-align: center;
  border: 1px solid #dfe4e5a8;
  box-shadow: 7px 10px 50px 0 #ced5dc26;
  height: fit-content;
  padding: 50px;
  border-radius: 34px;
}

.unavailable a {
  text-decoration: underline;
  color: #47a1ed;
}

.unavailable h1 {
  color: #09323a;
  font-weight: 600;
}

.unavailable h4 {
  color: #09323a80;
  font-weight: 400;
}

.unavailable .logo {
  width: 200px;
}
.resumewriter {
  display: flex;
  max-width: 100vw;
  width: 100%;
  padding: 40px 0;
  padding-left: 20vw;
  padding-right: 20vw;
  padding-bottom: 50px;
  position: relative;
  margin-right: 20vw;
  background: linear-gradient(0deg, #fff, #e8fcff);

  &::before {
    content: '';
    z-index: 0;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    height: 3px;
    background: linear-gradient(
      90deg,
      #fcb504 0%,
      #fcb504 25%,
      #fd3f67 25%,
      #fd3f67 50%,
      #0ac9ef 50%,
      #0ac9ef 75%,
      #05d6a2 75%,
      #05d6a2 100%
    );
  }

  .profile {
    height: 100px;
    width: 100px;
    margin-right: 40px;
    border-radius: 50px;
  }

  .body {
    height: fit-content;
    width: calc(100% - 140px);
    display: flex;
    flex-direction: column;

    a {
      font-weight: 500;
      font-style: normal;
      color: #47656b;
      font-size: 15px;
      letter-spacing: 0;
      display: flex;
      align-items: center;
      cursor: pointer;
      border-radius: 10px;
      background: #ffffff;
      opacity: 1;
      border: 1px solid rgba(223, 228, 229, 0.658824);
      width: fit-content;
      height: fit-content;
      padding: 8px;
      margin: -5px 0 0 15px;

      &:hover {
        background: #2a3d42;
        box-shadow: 2px 8px 10px 0 rgba(206, 213, 220, 0.1);
      }
    }

    .name {
      display: flex;
      font-size: 25px;
      margin-bottom: 10px;
      width: 100%;
      color: #09323a;
      font-size: 24px;
      font-weight: bold;
      line-height: 29px;
    }

    .description {
      width: 100%;
      color: #09323a90;
      font-size: 15px;
      font-weight: 400;
      line-height: 23px;
    }
  }
}

.banner {
  display: flex;
  background: #fff;
  font-size: 18px;
  //margin-left: -10vw;
  // margin-right: -10vw;
  padding: 30px;
  border-radius: 10px;
  border: 1px solid rgba(223, 228, 229, 0.658824);
  box-shadow: 4px 25px 45px 0px rgba(112, 135, 139, 0.1);
  background-image: url('https://storage.googleapis.com/dratini/Background3.png');
  background-position: right;
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 60px;
  margin-bottom: 60px;
  width: calc(100% + 10vw);
  //max-width: 60vw;
  max-width: 1250px;
  //max-width: calc(20vw + 700px);
  justify-content: center;
  align-items: center;

  &.appsumo {
    margin: 50px auto;
    padding: 30px 30px 20px 30px;
    overflow: hidden;
    width: fit-content; //(100% + 10vw);
    max-width: 950px;
    .blueButton {
      margin: 20px;
      padding: 0;
    }
    .image {
      margin: 0px 40px 0px 30px;
      height: 61px;
      width: auto;
    }
  }

  &.top {
    display: flex;
    background: none;
    font-size: 18px;
    padding: 10px 30px;
    border-radius: 0;
    border: 0;
    box-shadow: none;
    margin-top: 0;
    margin-bottom: 0;
    width: 100%;
    max-width: 100%;
    background-color: #b561cf;
    position: absolute;
    z-index: 0;
    top: 85px;
    min-height: 40px;
    max-height: 40px;
    .close {
      position: absolute;
      right: 20px;
      width: 30px;
      height: 30px;
      border-radius: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      -webkit-filter: invert(100%);
      filter: invert(100%);
    }
    .close:hover {
      background: #ced5dc26;
    }
    .subheadline {
      width: 100%;
      font-size: 14px;
      color: white;
      width: fit-content;
    }
    .whiteButton {
      color: #09323a;
      margin: 0 10px;
      padding: 0 20px;
      min-height: 24px;
      max-height: 24px;
      font-size: 13px;
      text-align: center;
      line-height: 0.8;
      font-weight: 600;
    }
  }

  &.sticky {
    &.slide{
      &.up{
        animation-name: slide-up;
        animation-duration: 0.25s;
        animation-fill-mode: forwards;
      }
      width: 400px;
      height: 400px;
      max-height: 400px;
      right: 0;
      margin-left: calc(100% - 410px);
      bottom:10px;
      justify-content:center;
      align-items: center;
      flex-direction: column;
      .image {
        margin: -10px auto 10px auto;
      }
      .text {
        margin:0px;
        text-align: center;     
      }
    }
    position: sticky;
    bottom: 0;
    width: 100%;
    z-index: 10;
    max-width: 100%;
    margin: 0;
    padding: 20px;
    max-height: 140px;
    justify-content: center;
    .close {
      position: absolute;
      right: 10px;
      top: 10px;
      width: 30px;
      height: 30px;
      border-radius: 20px;
      border: 1px solid #e8eced;
      background: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .close img {
      width: 22px;
    }

    .close:hover {
      background: #ced5dc26;
    }

    .image {
      margin-top: -80px;
    }

    .text {
      //max-width: calc(100% - 400px);
      width: fit-content;

      .headline {
        text-align: center;
        font-size: 20px;
      }

      .subheadline {
        width: 100%;
        text-align: center;
        font-size: 15px;
      }
    }

    .blueButton {
      margin: auto 0 auto 20px;
      font-size: 16px;
    }
  }

  .text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    // width: calc(100% - 515px);
    margin: 0 0 0 20px;
    .headline {
      margin-bottom: 10px;
      width: 100%;
      color: #09323a;
      font-size: 24px;
      font-weight: bold;
      line-height: 29px;
    }

    .subheadline {
      margin-bottom: 10px;
      width: 100%;
      color: #09323a80;
      font-size: 18px;
      font-weight: 500;
      line-height: 29px;
    }
    span {
      font-weight: 900;
      color: #fa3b63;
    }
  }

  .blueButton {
    border: 0;
    text-align: center;
    //margin-left: 50px;
    width: fit-content;
    padding: 0 40px;
    min-width: 180px;
  }

  .image {
    margin: -20px 85px -5px 0;
    height: 150px;
    width: auto;
  }
}

@keyframes slide-up {
  0% {
    transform: translateY(100%);
    opacity: 1;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.newsletter {
  display: flex;
  background: #fff;
  font-size: 18px;
  margin: auto;
  padding: 40px;
  border-radius: 10px;
  border: 1px solid rgba(223, 228, 229, 0.658824);
  box-shadow: 4px 25px 45px 0px rgba(112, 135, 139, 0.1);
  background-position: calc(100% - 40px) 40px;
  background-size: 60px;
  background-repeat: no-repeat;
  margin-bottom: 60px;
  max-width: 80vw;
  width: 80vw;
  margin-left: 10vw;
  margin-right: 10vw;
  justify-content: center;
}

.newsletter > div {
  &.close {
    position: absolute;
    right: 40px;
    top: 40px;
    width: 30px;
    height: 30px;
    border-radius: 20px;
    border: 1px solid #e8eced;
    background: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
      background: #ced5dc26;
    }

    img {
      width: 22px;
    }
  }

  width: 50%;
}

.newsletter div.image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.newsletter div.image img {
  width: 60%;
  max-width: 250px;
}

.newsletter div.text {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 20px;
  width: calc(50% - 20px);
}

.newsletter div.text .newsTitle {
  font-size: 32px;
  margin-bottom: 60px;
  width: 100%;
  color: #09323a;
  font-size: 24px;
  font-weight: bold;
  line-height: 29px;
}

.emailinput .textInput {
  min-width: 395px;
  margin-right: 10px;
  width: 395px;
  height: 50px;
  border-radius: 25px;
  background: #ffffff;
  border: 1px solid rgba(223, 228, 229, 0.658824);
  box-shadow: 3px 6px 9px rgba(154, 175, 180, 0.08);
  font-size: 15px;
  font-family: 'Gilroy';
}

.newsletter .emailinput {
  display: flex;
  margin: 30px auto 0px auto;
}

.newsletter .blueButton {
  margin: 0 0 0 10px;
  border: 0;
  min-height: 50px;
  border-radius: 27px;
}

.newsletter .emailinput .textInput {
  max-height: 50px;
  min-width: auto;
  border: 1px solid #e8eced;
  padding: 0 15px;
}

.newsletter input.textInput:focus {
  background: #fff;
  outline: 0;
  border-radius: 25px;
  border: 1px solid #e8eced;
  box-shadow: 0 0 0 3px rgba(213, 225, 228, 0.4);
}

.newsletter input.textInput:hover {
  background: #fff;
  border-radius: 25px;
  border: 1px solid #e8eced;
  box-shadow: 0 0 0 3px rgba(213, 225, 228, 0.4);
}

.livenoti {
  display: flex;
  flex-direction: column-reverse;
  position: fixed;
  bottom: 20px;
  left: 110px;
  z-index: 100;
  width: fit-content;
  height: fit-content;
}

.livenoti .notification {
  align-items: flex-start;
  cursor: pointer;
  display: flex;
  width: 290px;
  padding: 20px;
  margin-top: 20px;
  border-radius: 16px;
  background: #09323ad9;
  font-weight: 600;
  font-style: normal;
  color: white;
  backdrop-filter: blur(2px);
}

.livenoti .notification .logop,
.livenoti .notification img {
  justify-content: center;
  align-items: center;
  display: flex;
  min-width: 40px;
  width: 40px;
  border-radius: 10px;
  margin-right: 15px;
}

.livenoti .notification .text {
  font-weight: 500;
  font-size: 14px;
  color: #ffffffb0;
  letter-spacing: 0.16px;
  line-height: 20px;
  word-break: break-word;
}

.livenoti .notification .text .strong {
  color: #ffffff;
  font-weight: bold;
}

/*

.edpromotion{
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 20!important;
  background: hsla(0,0%,100%,.7);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
}

.edpromotion .newsletter {
  position: relative;
  max-height: 410px;
  max-width: 685px;
  background: #fff;
  flex-wrap: wrap;
  font-size: 18px;
  margin: auto;
  background-position: 35px 35px;
  background-size: 60px;
  background-repeat: no-repeat;
}
.edpromotion .newsletter .text{
  margin: 40px 0 0 40px;
  width: calc(50% - 80px);
}
.edpromotion .newsTitle{
  margin-top: 20px;
  margin-bottom: 20px!important;
}
.edpromotion .close{
  position: absolute;
  right: 30px;
  top: 30px;
  width: 30px;
  height: 30px;
  border-radius: 20px;
  border: 1px solid #e8eced;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.edpromotion .close img{
  width: 22px;
}
.edpromotion .close:hover {
  background: #CED5DC26;
}
.edpromotion .image img {
  width: 80%!important;
}

.edpromotion .title {
  margin-top: 120px;
  left: 75px;
  font-size: 26px;
  margin-bottom: 15px;
  margin-left: 65px;
  width: 287px;
  height: 66px;
  color: #09323a;
  font-size: 24px;
  font-weight: bold;
  line-height: 29px;

}
.edpromotion .emailinput {
  display: flex;
  width: 100%;
  margin: 20px 40px;
}
.edpromotion .description{
  opacity: 0.5960054;
  width: 271px;
  color: #09323a;
  font-size: 15px;
  font-weight: 400;
  line-height: 23px;
}*/
.videoblog {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  width: 100%;
  padding-top: 85px;
  margin-top: 0px;

  .selector {
    min-height: 46px;
    margin: 20px auto;

    .top.summaary {
      left: 5px;
    }

    .top.transcript {
      left: 149px;
    }
  }

  .videosection {
    background: #f7f9fb;
    display: flex;
    z-index: 4;
    padding-bottom: 20px;
    padding-left: 10vw;
    padding-right: 10vw;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 25px;
    width: 100vw;
    max-width: 100vw;
    padding-top: 20px;
    //max-height: calc(50vh + 55px);
    height: 100%;

    //height: calc(50vh + 55px);
    /*
    &::after{
      content: '';
      position: absolute;
      width: 120%;
      left: -10vw;
      height: 40px;
      bottom: -40px;
      background: linear-gradient(180deg,#f7f9fb,#ffffff00 75%);
    }*/
    .videoleft {
      height: 100%;
      width: 70%;
      max-width: 840px;
    }

    .title {
      color: #09323a;
      font-family: 'Gilroy';
      font-size: 23px;
      font-weight: bold;
      line-height: 38px;
      text-transform: capitalize;
      margin: 10px auto;
    }

    .videoPlayer {
      width: fit-content;
      height: fit-content;
      max-width: 100%;
      position: relative;
      margin: auto;
      border-radius: 10px;
      display: flex;
      align-items: center;

      .play {
        cursor: pointer;
        position: absolute;
        margin: auto;
        left: 0;
        right: 0;
      }

      video {
        width: fit-content;
        border-radius: 10px;
        max-width: 90%;
        margin: auto;
        // height: fit-content;
        // max-height: calc(50vh - 195px);
      }
    }

    .transcription {
      margin: 0 20px;
      height: 100%;
      overflow: auto;
      max-width: 360px;
      width: 30%;
      max-height: 45vh;
      position: relative;

      p {
        margin: 5px 0 0 0;
        color: #09323a;
        font-size: 15px;
        letter-spacing: 0;
        line-height: 20px;
        max-width: 90%;
        word-break: break-word;
      }
    }
  }

  .summary {
    //margin-top: calc(50vh + 100px);
    margin-left: 20vw;
    margin-right: 20vw;
    display: flex;
    flex-direction: column;
    max-width: 700px;

    .title {
      color: #09323a;
      font-family: 'Gilroy';
      font-size: 24px;
      font-weight: bold;
      line-height: 38px;
      margin-bottom: 5px;
      margin-top: 10px;
      text-transform: capitalize;
    }

    .resume {
      font-size: 20px;
      line-height: 32px;
      color: rgba(9, 50, 58, 0.6);
      letter-spacing: 0;
      margin: 22px 0 0;
    }
  }
}

@media (max-width: 1200px) {
  .single .subtitle .length {
    display: none;
  }

  .article.blog .banner {
    width: 100%;

    .image {
      height: 110px;
    }

    .headline {
      font-size: 20px;
    }

    .subheadline {
      font-size: 14px;
    }

    .blueButton {
      font-size: 14px;
    }
  }

  .banner {
    width: 100%;
  }
}

@media (max-width: 1000px) {
  .similararticles .articles {
    justify-content: center;
  }

  .similararticles .articles .single {
    flex-basis: calc(50% - 45px);
  }

  .similararticles.nobanner .articles .single:nth-child(2) {
    margin-right: 0;
  }

  .lastarticles {
    flex-direction: column;
  }

  .lastarticles > div {
    width: 100% !important;
  }

  .lastarticles .rightsection {
    margin-top: 40px;
    margin-left: 0;
  }

  .lastarticles .leftsection {
    width: 100%;
  }

  .blogarticle {
    .content {
      min-width: 250px;
    }

    .article {
      max-width: calc(100% - 250px);

      .body {
        width: 100%;
      }
    }
  }

  .videoblog {
    .videosection {
      flex-direction: column;
      align-items: center;

      /*max-height: calc(50vh + 85px);*/
      //height: calc(50vh + 85px);
      .videoPlayer {
        width: 100%;
        display: flex;
        max-width: 100%;

        video {
          margin: auto;
        }
      }

      .title {
        text-align: center;
      }

      .transcription {
        width: 100%;
        max-width: 100%;
      }
    }
  }

  .article.blog .banner {
    flex-direction: column;
    align-items: center;

    &.sticky {
      flex-direction: row;
    }

    .image {
      margin: auto;
    }

    .text {
      display: flex;
      align-items: center;
      text-align: center;
      width: 100%;
    }

    .blueButton {
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

@media (max-width: 980px) {
  header .menu a:nth-child(1) {
    display: none;
  }

  footer div:nth-child(2) {
    display: none;
  }
}

@media (max-width: 850px) {
  .videoblog .videosection {
    padding-left: 0;
    padding-right: 0;

    .videoleft {
      max-width: none;
      width: 100%;
    }
  }

  .videoblog {
    .summary {
      max-width: none;
      width: 90vw;
    }
  }

  .blogarticle {
    flex-direction: column;
    padding: 0 0px;

    .content {
      display: none;
      top: 0px;
      position: relative;
      width: 100%;
      max-width: 100%;
      margin-bottom: 0;
      margin-top: 0px;
    }

    .article {
      max-width: 100%;
      width: 90%;
      margin-left: 5%;
      margin-right: 5%;
    }
  }
}
@media (max-width: 630px) {
  header {
      margin: 0;
      width: 100%;
      height: 58px;

  }
  header .logo {
    height: 20px;
}
}
@media (max-width: 780px) {
  .banner .image {
    display: none;
  }

  .banner .text {
    max-width: calc(100% - 200px);
    text-align: center;
  }

  .banner.sticky.slide.up {
    .image{
      display: flex;
    }
    .text {
      min-width:100%;
      text-align: center; 
     
    }

  }
}

@media (max-width: 710px) {
  .introlanding {
    background-image: none !important;
  }

  .banner {
    margin-left: 0;
    margin-right: 0;
  }

  .newsletter {
    width: calc(90vw - 80px) !important;
  }

  .newsletter .image {
    display: none !important;
  }

  .newsletter .text {
    width: 100% !important;
  }

  .edpromotion .text {
    width: 100% !important;
  }

  .newsletter .newsTitle {
    width: 80% !important;
  }

  header .menu a:nth-child(3) {
    display: none;
  }

  .article .body {
    width: 80vw;
    margin-left: 0vw;
  }

  .resumewriter {
    padding-left: 10vw;
    padding-right: 10vw;
  }

  .article p {
    font-size: 14px;
    line-height: 26px;
    color: rgba(9,50,58,.9);
    letter-spacing: 0;
    margin: 2px 0 0;
  }

  .article li {
    font-size: 18px;
  }

  footer div:nth-child(4) {
    display: none;
  }
}

@media (max-width: 600px) {
  header .menu a:nth-child(4) {
    display: none;
  }

  footer div:nth-child(5) {
    display: none;
  }
}

@media (max-width:520px) {
  /*.banner.sticky .text {
    max-width: calc(100% - 200px);
  }*/
  .banner.sticky {
    max-height: 200px;
  }


  .banner.sticky.slide.up {
    max-height: 300px;
    width: 300px;
    max-width: 300px;
    margin-left: auto;
    padding:0;
    .image{
      margin: -10px auto 10px auto;
      height: 100px;
      width: auto;
      display: none;
    }
    .text {
      min-width:100%;
      margin-top:50px;
      margin-bottom: -20px;;
      text-align: center; 
      padding: 0 20px;
      .headline {
        text-align: center;
        font-size: 18px;
      }
      .subheadline {
        width: 100%;
        text-align: center;
        font-size: 15px;
      } 
    }
  }
  

  .subheader .subtitle .length {
    display: none;
  }

  .newsletter .newsTitle {
    text-align: center;
  }

  .newsletter .description {
    text-align: center !important;
    width: auto;
  }

  .newsletter .emailinput {
    flex-wrap: wrap;
    justify-content: center;
  }

  .newsletter .emailinput .blueButton {
    margin: 10px 0;
  }
}

@media (max-width: 480px) {
  .banner {
    flex-direction: column;
    .text {
      max-width: 100%;
    }
    .subheadline {
      display: none;
    }
  }

  .banner.sticky.slide.up {
    .text {
      .subheadline {
        display:flex;
      } 
    }
    .blueButton{
      max-width: auto;
      margin:auto 10px;
      font-size: 15px;
    }
  }

  .subheader .subtitle .length {
    display: none;
  }

  .newsletter {
    width: 80vw !important;
  }

  .similararticles .articles .single {
    /*flex-basis: calc(100%);*/
    flex-basis: calc(100% - 60px);
    margin-right: 0;
    margin-left: 0;
  }

  .similararticles .articles {
    justify-content: center;
    margin-top: 0;
    margin-bottom: 0;
  }

  .similararticles .right {
    position: relative;
    order: -1;
    rotate: 270deg;
    margin: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  .similararticles .left {
    position: relative;
    order: 5;
    rotate: 90deg;
    margin: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  .resumewriter {
    > img {
      display: none;
    }

    .body {
      width: 100%;
    }
  }

  .blogarticle .article {
    width: calc(100% - 40px);
    margin: 20px;
  }

}

@media (max-width:320px) {
  .banner.sticky.slide.up {
    max-height: 250px;
    width: auto;
    max-width: auto;
    margin-left:10px;
    .text {
      .subheadline {
        display: none;
      } 
    }
    .blueButton{
      max-width: auto;
      min-width:auto;
      margin:auto 10px;
      font-size: 15px;
    }
  }
}

.extra-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .back-button {
    background-color: transparent;
    display: flex;
    align-items: center;
    cursor: pointer;
    display: flex;
    margin: 0px 0px 0px 30px;
    width: calc(100% - 80px);
    font-size: 19px;
    color: #09323a;
    padding-bottom: 10px;
    padding-top: 20px;
}

    img {
      margin-right: 5px;
    }

    &:hover {
      color: #16ccf0;
    }
  }

.title {
  font-size: 1.5rem;
  margin-bottom: 10px;
}
//* Ensure all text within elements with the class content use the specified font and color */
.content {
  font-family: 'Gilroy', Arial, sans-serif;
  color: #798b8b;
}

.content .title, /* Add class for titles within content */
.content .text, /* Add class for text within content */
.content p, /* Apply to all paragraphs within content */
.content a, /* Apply to all anchor tags within content */
.content li, /* Apply to all list items within content */
.content span /* Apply to all span tags within content */ {
  color: #798b8b;
}

/* Add bold style on hover */
.post-cover:hover .post-title, /* Add hover effect */
.post-cover:hover .post-content {
  font-weight: bold;
}

/* Apply styles for the back-button */
.back-button {
  background-color: transparent;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 0px 0px 0px 30px;
  width: calc(100% - 80px);
  font-size: 19px;
  color: #09323a;
  padding-bottom: 10px;
  padding-top: 20px;
  border: none; /* Remove border */
  border-radius: 10px;
  text-transform: capitalize;
  font-weight: 600;
}

.back-button img {
  margin-right: 5px;
}

.back-button:hover {
  color: #000;
}
.sharing {
  display: flex;
  flex-direction: column;
  align-items: self-start;
  padding: 35px;
  border-radius: 8px;
}

.sharing span {
  color: #09323a;
  font-size: 14px; /* Smaller font size for the title */
  line-height: 24px;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.sharing .icons {
  display: flex;
}

.sharing a {
  margin: 5px 9px;
  transition: transform 0.2s;
}

.sharing a:hover {
  transform: scale(1.2);
}

.sharing svg {
  width: 22px;
  height: 18px;
  fill: currentColor;
  flex-shrink: 0;
}

// Media query for smaller devices (phones)
@media (max-width: 600px) {
  .sharing {
    flex-direction: column;
    align-items: center; /* Align items to center */
  }

  .sharing .icons {
    flex-direction: row; /* Ensure icons stay in a row */
    justify-content: center; /* Center the icons */
  }

  .sharing a {
    margin: 10px 5px;  // Increase vertical space between list items
  }

  .sharing svg {
    width: 18px;
    height: 18px;
  }
}
