.mediaheader {
  background: transparent;
  z-index: 6;
  &::after {
    background: transparent;
  }
  .darkButton {
    width: 150px;
    margin: 0 20px;
    padding: 0 25px;
    min-height: 44px;
    max-height: 44px;
    &.share {
      width: 44px;
      padding: 0;
      margin: 0;
      position: relative;
      img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0px;
        margin: auto;
        height: 20px;
      }
    }
  }
}
header.mediaheader .menu a:hover {
  color: white;
}
.darkButton {
  padding: 0 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 44px;
  max-height: 44px;
  font-size: 15px;
  color: white;
  font-weight: bold;
  font-style: normal;
  letter-spacing: 0;
  border-radius: 22px;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.2);
  transition: 0.3s;
  &:hover {
    color: white;
    background: rgba(255, 255, 255, 0.4);
  }
}
.media {
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 5;
  background: #09323a;
  &.empty {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    img {
      margin-bottom: 20px;
      width: 150px;
    }
    span {
      opacity: 0.38;
      font-weight: 700;
      justify-content: center;
      font-style: normal;
      font-size: 18px;
      color: #fff;
      letter-spacing: 0;
    }
  }
  .doc {
    position: absolute;
    bottom: 0;
    top: 15%;
    max-height: 85%;
    height: 100%;
    width: 80vw;
    left: 10vw;
  }
  .image,
  .video {
    max-width: 95%;
    max-height: 80%;
    width: auto;
    height: 70%;
    border-radius: 10px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0px;
    margin: auto;
  }
  .video {
    cursor: pointer;
  }
  .playbutton {
    display: flex;
    position: absolute;
    pointer-events: none;
    margin: auto;
    top: 0;
    cursor: pointer;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  flex-direction: column;
  width: 100%;
  height: 100%;
  .title {
    font-weight: bold;
    font-style: normal;
    font-size: 18px;
    letter-spacing: 0;
    color: #ffffff;
    margin-bottom: 20px;
  }
  .description {
    opacity: 0.38;
    width: 214px;
    font-weight: 500;
    font-style: normal;
    font-size: 15px;
    letter-spacing: 0;
    text-align: center;
    color: #ffffff;
  }
  img {
    height: 74px;
    width: 74px;
    margin-bottom: 65px;
    animation: rotation 2s infinite linear;
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
@media (max-width: 500px) {
  .media .doc {
    width: 100vw;
    left: 0vw;
    top: 100px;
    height: calc(100% - 100px);
  }
}
