/*.homepage{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.homepage > section{
  max-width: 1500px;
  padding: 0px calc(100vw - 1500px);
}

.introlanding {
  margin-bottom: -100px;
  padding-bottom: 0px;
  width: calc(100vw - 20px);
  text-align: center;
}
*/
.homepage .clients.landing {
  margin-top: 50px;
}

.introlanding {
  display: flex;
  justify-content: center;
  z-index: 1;
  flex-direction: column;
  align-items: center;
  background-image: url(/images/main/LeftBack.svg), url(/images/main/RightBack.svg);
  background-position: left, right;
  background-repeat: no-repeat, no-repeat;
  margin-top: 175px; //135px; //topbanner
  margin-bottom: 90px;
  padding-bottom: 0px;
  width: 100%;
  text-align: center;

  .badges {
    margin: 20px 0 0 0;
    display: flex;
    align-items: center;

    >img {
      margin: 0 5px;
    }
  }
}

.reviewslanding {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;

  >div {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    margin: 5px;
  }

  .stars {
    justify-content: center;
    align-items: center;
    font-size: 16px;

    span {
      margin-left: 8px;
      color: rgba(9, 50, 58, 0.4509803922);
    }

    img {
      height: 16px;
      margin: 1px;
    }
  }

  .text {
    opacity: 0.8;

    img {
      height: 15px;
      width: auto;
      margin: 5px;
      cursor: pointer;
    }
  }
}

.introlanding .list {
  display: flex;
  flex-direction: column;
  align-items: center;
  break-inside: avoid;
  width: fit-content;
  height: 55px;
  -webkit-animation-name: change;
  -webkit-animation-duration: 20s;
  -webkit-animation-iteration-count: infinite;
  animation-name: change;
  animation-duration: 20s;
  animation-iteration-count: infinite;
}

@keyframes change {

  0%,
  8%,
  100% {
    transform: translate3d(0, 0, 0);
  }

  10%,
  18% {
    transform: translate3d(0, -50px, 0);
  }

  20%,
  28% {
    transform: translate3d(0, -100px, 0);
  }

  30%,
  38% {
    transform: translate3d(0, -150px, 0);
  }

  40%,
  48% {
    transform: translate3d(0, -200px, 0);
  }

  50%,
  58% {
    transform: translate3d(0, -250px, 0);
  }

  60%,
  68% {
    transform: translate3d(0, -200px, 0);
  }

  70%,
  78% {
    transform: translate3d(0, -150px, 0);
  }

  80%,
  88% {
    transform: translate3d(0, -100px, 0);
  }

  90%,
  98% {
    transform: translate3d(0, -50px, 0);
  }
}

.introlanding .list span {
  display: none;
}

.introlanding .title {
  display: flex;
  margin-top: 0px;
  color: #09323a;
  font-size: 40px;
  font-weight: bold;
  font-style: normal;
  line-height: 54px;
  text-align: center;
}

.introlanding .description {
  margin-top: 0px;
  color: #09323a73;
  line-height: 30px;
  letter-spacing: 0;
  font-size: 18px;
  text-align: center;
  font-weight: normal;
  font-style: normal;
}

.introlanding .title {
  margin-bottom: 0px;
  margin-top: 0px;
  max-width: 650px;
  font-size: 40px;
  line-height: 50px;
  overflow: hidden;
}

.introlanding .title .highlight {
  color: #16daf4;
}

.introlanding .description {
  width: 620px;
  margin-top: 35px;
  line-height: 30px;
  font-size: 18px;
  max-width: 80vw;
}

.introlanding .platform {
  position: relative;
  max-height: 540px;
  max-width: 80vw;
  height: 47vw;
  width: 940px;
  margin-top: 60px;
  overflow: hidden;
  border-radius: 23px 23px 0 0;
  box-shadow: 0 10px 80px 0 rgba(232, 236, 237, 0.6);
}

.introlanding .platform img {
  left: 0;
  position: absolute;
  width: -webkit-fill-available;
}

.introlanding .platform img.mask {
  z-index: 3;
}

.introlanding .platform img.front {
  z-index: 2;
  transition: 0.2s;
}

.multicolors {
  height: 4px;
  width: 100%;
  max-width: 80vw;
  background: linear-gradient(90deg,
      #fcb504 0%,
      #fcb504 25%,
      #fd3f67 25%,
      #fd3f67 50%,
      #0ac9ef 50%,
      #0ac9ef 75%,
      #05d6a2 75%,
      #05d6a2 100%);
}

.introlanding>.barcolors {
  height: 4px;
  width: 100%;
  max-width: 80vw;
  background: linear-gradient(90deg,
      #fcb504 0%,
      #fcb504 25%,
      #fd3f67 25%,
      #fd3f67 50%,
      #0ac9ef 50%,
      #0ac9ef 75%,
      #05d6a2 75%,
      #05d6a2 100%);
}

.zapier>.barcolors {
  height: 4px;
  width: 100%;
  max-width: 80vw;
  background: linear-gradient(90deg,
      #fcb504 0%,
      #fcb504 25%,
      #fd3f67 25%,
      #fd3f67 50%,
      #0ac9ef 50%,
      #0ac9ef 75%,
      #05d6a2 75%,
      #05d6a2 100%);
}

.barcolors1 {
  height: 4px;
  width: 100%;
  background: linear-gradient(90deg,
      #fcb504 0%,
      #fcb504 25%,
      #fd3f67 25%,
      #fd3f67 50%,
      #0ac9ef 50%,
      #0ac9ef 75%,
      #05d6a2 75%,
      #05d6a2 100%);
}

.featuresbanner {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 100px;
}

.featuresbanner .features-navigation {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px auto;
  width: fit-content;
  position: relative;
  max-width: 100vw;
}

.featuresbanner .features-navigation span.bar {
  height: 3px;
  width: 153px;
  position: absolute;
  left: 20px;
  bottom: 0;
  transition: 0.5s;
}

.featuresbanner .features-navigation>div {
  margin: 0 20px;
  padding: 10px 0;
  cursor: pointer;
  border-bottom: 3px solid transparent !important;
  font-weight: 600;
  font-size: 18px;
  color: #09323a73;
  text-align: center;
  line-height: 25px;
  text-transform: capitalize;
}

.featuresbanner .features-navigation>div:nth-child(1) {
  border-bottom: 3px solid #a14dba;
}

.featuresbanner .feature {
  display: flex;
  justify-content: center;
  max-width: 1200px;
  width: 90vw;
  margin: 0 auto;
  padding: 0;
}

.featuresbanner .feature>div {
  display: flex;
  flex-direction: row;
}

.featuresbanner .feature>div.task {
  display: flex;
}

.featuresbanner .feature .infofeature {
  min-width: 200px;
  -webkit-animation: fadeInVideo 1s;
  animation: fadeInVideo 1s;
  margin-right: 5%;
  margin-bottom: 60px;
  margin-top: 20px;

  .description {
    text-align: left;
    margin: 0;

    &.replaces {
      margin: 30px 0 15px 0;
    }
  }
}

@keyframes fadeInText {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.featuresbanner .feature .image {
  width: calc(95% - 200px);
  min-width: calc(95% - 200px);
  margin: 60px 5% 60px 0%;
  border-radius: 16px;
  box-shadow: 0 19.0016px 90.2576px rgba(158, 168, 163, 0.4);
  -webkit-animation: fadeInVideo 1s;
  animation: fadeInVideo 1s;
}

@keyframes fadeInVideo {
  from {
    filter: blur(1px);
  }

  to {
    filter: blur(0px);
  }
}

.featuresbanner>.title {
  font-size: 40px;
  margin: 40px 40px 40px 40px;
  text-align: center;
}

.featuresbanner .infofeature .title {
  font-size: 18px;
  margin: 19px 0;
  font-weight: 600;
  margin-top: auto;
}

.featuresbanner .infofeature .blueButton {
  margin-top: auto;
}

.clients.landing {
  margin-bottom: 0px;
  background: #ffffff;
}

.campaign.intro {
  display: flex;
  padding: 50px 10vw;
  padding-bottom: 200px;
  z-index: 1;
  align-items: center;
  justify-content: center;
  background: linear-gradient(0deg, #ffffff 0%, #f0f6f7);
  /*background-image: url(/images/LeftBack.svg), url(/images/RightBack.svg);*/
  /*background-position: 70%, right;*/
  /*background-repeat: no-repeat, no-repeat;*/
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 50px;
  padding-bottom: 50px;
}

.infofeature {
  width: calc(40% - 150px);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.infofeature .description {
  text-align: left;
}

.infofeature .description.replaces {
  font-weight: 600;
  color: #09323a;
  display: flex;
  margin-bottom: 15px;
  margin-top: 30px;
}

.infofeature .description.replaces img {
  margin: 0 4px;
}

.infofeature .description>span.replace {
  border-radius: 6px;
  color: #fff;
  letter-spacing: 0;
  line-height: 14px;
  align-items: center;
  display: flex;
  text-align: center;
  font-weight: 600;
  margin-left: 10px;
  font-style: normal;
  margin-left: 5px;
  font-size: 12px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0px 14px 0px;
}

.infofeature .description>span.replace:nth-child(0) {
  background: #05d6a2;
}

.infofeature .description>span.replace:nth-child(1) {
  background: #fd3f67;
}

.infofeature .description>span.replace:nth-child(2) {
  background: #fcb504;
}

.infofeature .description>span.replace:nth-child(3) {
  background: #0ac9ef;
}

.infofeature .description span {
  color: #09323abf;
  font-weight: 600;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

.promo-title {
  color: #ff5252;
  margin-bottom: 15px;
  font-size: 22px;
  text-transform: uppercase;
}

.promo-desc {
  color: #333333;
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 1.5;
}

.promo-button {
  color: #ffffff;
  background-color: #ff5252;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 18px;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.promo-button:hover {
  background-color: #ff8787;
}

.infofeature .description p {
  margin: 0px 0px 10px 0;
  color: #09323a73;
  line-height: 18px;
  font-size: 13px;
}

.campaign.intro .column {
  width: 50%;
  max-width: 600px;
}

.campaign.intro .column .title {
  text-align: left;
  font-size: 40px;
  line-height: 70px;
  max-width: 400px;

  span {
    color: #0ac9ef;
  }
}

.campaign.intro .column .description {
  text-align: left;
  margin-top: 0px;
  color: #09323a73;
  line-height: 30px;
  letter-spacing: 0;
  font-size: 15px;
  text-align: left;
  font-weight: normal;
  font-style: normal;
  max-width: 400px;
}

.campaign.intro .column .emailinput {
  text-align: left;
  margin: 60px 0;
  flex-direction: column;
}

.campaign.intro .column .platform {
  position: relative;
  width: 100%;
  height: 20vw;
  overflow: hidden;
  border-radius: 23px 23px 0 0;
  box-shadow: 0 10px 80px 0 rgba(232, 236, 237, 0.6);
}

.campaign.intro .column .newway {
  /* margin: -50px -50px -50px 0;
  width: calc(100% + 50px);*/
  margin: 0;
  width: 100%;
}

.benefits {
  position: relative;
  display: flex;
  flex-direction: column;
  background: linear-gradient(0deg, #ffffff, #e8fcff);
  padding: 100px 10vw 100px 10vw;
  width: 100%;
  overflow: hidden;
}

.benefits>.title {
  color: #09323a;
  margin-bottom: 50px;
  margin-top: 0px;
  width: 90%;
  margin: auto;
  text-align: center;
  font-size: 40px;
  line-height: 50px;
}

.benefits .benefit {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 50px auto;
  max-width: 1200px;
}

.benefits .benefit .information {
  width: 50%;
  margin: auto;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.benefits .benefit .information .blueButton,
.benefits .benefit .information .whiteButton {
  width: 200px;
  margin-right: 200px;
}

.benefits .benefit .information img {
  width: 75px;
  height: 75px;
  padding: 26px;
  border: 1px solid rgba(223, 228, 229, 0.658824);
  border-radius: 22px;
}

.benefits .benefit .information .title {
  margin-top: 20px;
  font-size: 30px;
  line-height: 42px;
  text-align: left;
  font-weight: bold;
  color: #09323a;
  margin-bottom: 0;
  max-width: 400px;
}

.benefits .benefit .information .description {
  line-height: 30px;
  font-size: 15px;
  text-align: left;
  font-weight: 500;
  color: #09323a73;
  max-width: 400px;
}

.benefits .benefit .image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  padding: 40px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
  }

  &.blue::before {
    bottom: -80px;
    right: -40px;
    opacity: 0.2;
    background-image: url(/images/main/blob-blue.svg);
  }

  &.yellow::before {
    bottom: -80px;
    left: 10px;
    opacity: 0.2;
    background-image: url(/images/main/blob-yellow.svg);
  }

  &.green::before {
    bottom: -80px;
    right: -40px;
    opacity: 0.2;
    background-image: url(/images/main/blob-green.svg);
  }
}

.benefits .benefit .image img {
  width: 100%;
  max-width: 460px;
  transform: translatex(0px) translatey(0px);
  animation: float 6s ease-in-out infinite;
}

.signinBox {
  /*display: none;*/
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  position: fixed;
  width: 360px;
  box-shadow: 4px 15px 35px 10px #47656b1a;
  border-radius: 16px;
  background: #fff;
  z-index: 5;
  padding: 50px 30px 35px 30px;
  right: 0;
  position: absolute !important;
  top: 60px;

  .blueButton {
    border: 1px solid transparent;
    margin: 8px;
    width: 100%;
  }

  .logo {
    position: relative;
    width: 36px;
  }

  .doublesign {
    display: flex;
    margin-top: 56px;
    margin-bottom: 30px;

    >div {
      height: 40px !important;

      >div {
        border-radius: 40px;
      }
    }

    .appleButton {
      margin-left: 20px;
      cursor: pointer;
      width: 150px;
      height: 40px;
      border-radius: 20px;
      overflow: hidden;
    }
  }

  .doublesign::after {
    position: absolute;
    content: '';
    z-index: 0;
    border-top: 1px solid #97979740;
    width: 300px;
    left: 0;
    right: 0;
    margin: auto;
    margin-top: -32px;
    height: 1px;
    background: white;
  }

  .doublesign::before {
    position: absolute;
    content: 'OR SIGN IN WITH';
    z-index: 1;
    text-align: center;
    color: #547176b3;
    left: 0;
    right: 0;
    margin: auto;
    margin-top: -38px;
    width: 160px;
    height: 20px;
    background: white;
    font-weight: 500;
    font-style: normal;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 0.5538462px;
  }
}

.signinBox input,
.popup input {
  position: relative;
  margin: 8px;
  width: 100%;
}

.signinBox input.textInput,
.popup input.textInput {
  padding-left: 25px;
  min-height: 44px;
  max-height: 44px;
  background: #ffffff;
  color: #09323a;
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  border-radius: 25px;
  border: 1px solid rgba(223, 228, 229, 0.658824);
  font-family: 'Gilroy', Arial, sans-serif;
}

.signinBox input.textInput:hover,
.popup input.textInput:hover {
  opacity: 0.85;
  background: #ffffff;
  border-radius: 25px;
  border: 1px solid #becdd0;
  box-shadow: 0 0 0 4px rgba(213, 225, 228, 0.4);
}

.signinBox input.textInput:focus,
.popup input.textInput:focus {
  opacity: 0.85;
  background: #ffffff;
  outline: 0;
  border-radius: 25px;
  border: 1px solid #0ac9ef;
  box-shadow: 0 0 0 4px rgba(10, 201, 239, 0.2);
}

.signinBox input.textInput:focus::placeholder,
.popup input.textInput:focus::placeholder {
  opacity: 0.3;
}

.signinBox .sublogo {
  margin-top: 25px;
  margin-bottom: 23px;
  color: #09323a;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0;
  font-size: 15px;
}

.signinBox .options {
  border-top: 1px solid #9aafb440;
  padding-top: 21px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-left: 30px;
  margin-right: 30px;
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  border-radius: 6px;
}

.signinBox .options>div {
  padding: 6px;
  border-radius: 5px;
}

.signinBox .options>div:hover {
  color: #09323a;
  opacity: 0.45;
}

.signinBox .options .problems {
  cursor: pointer;
}

.signinBox .options .signup {
  cursor: pointer;
}

.errorpage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;

  footer {
    bottom: 0;
  }

  .oops {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 840px;
    height: 555px;
    background: #ffffff;
    box-shadow: 4px 15px 35px 0 rgba(71, 101, 107, 0.05);
    border-radius: 16px;

    .error {
      position: absolute;
      left: 0;
      top: -50px;
      font-size: 20px;
      color: #09323a;
      letter-spacing: 0;
      font-weight: bold;
      font-style: normal;
      line-height: 54px;
      text-align: center;
    }

    .title {
      font-size: 20px;
      color: #09323a;
      letter-spacing: 0;
      font-weight: bold;
      font-style: normal;
      line-height: 54px;
      text-align: center;
    }

    .description {
      opacity: 0.4;
      font-weight: normal;
      font-size: 14px;
      color: #09323a;
      max-width: 400px;
      line-height: 24px;
      letter-spacing: 0;
      text-align: center;
      margin-bottom: 30px;
    }
  }
}

@keyframes float {
  0% {
    transform: translatex(0px) translatey(0px);
  }

  25% {
    transform: translatex(-5px) translatey(-5px);
  }

  75% {
    transform: translatex(5px) translatey(5px);
  }
}

@media (max-width: 800px) {
  .benefits {
    padding: 50px 2vw 50px 2vw;
  }

  .benefits .benefit {
    flex-direction: column;
  }

  .benefits .benefit>div {
    width: 100% !important;
  }

  .benefits .benefit .information {
    order: 2;
  }

  .benefits .benefit .information>img {
    margin: 0 auto;
  }

  .benefits .benefit .information .title {
    font-size: 20px;
    line-height: 26px;
    max-width: 100%;
    text-align: center;
  }

  .benefits .benefit .information .description {
    font-size: 15px;
    line-height: 22px;
    max-width: 100%;
    text-align: center;
  }

  .benefits .benefit .information .blueButton {
    margin: 0 auto;
  }

  .campaign.intro {
    flex-direction: column;
    padding: 40px 2vw;
  }

  .campaign.intro .column {
    width: 100%;
  }

  .campaign.intro .column .title,
  .campaign.intro .column .description {
    max-width: 100%;
    text-align: center;
  }

  .featuresbanner .feature>div {
    flex-direction: column;
  }

  .featuresbanner .feature .infofeature {
    order: 2;
    width: 100%;
  }

  .featuresbanner .feature .image {
    width: 100%;
  }
}

@media (max-width: 600px) {

  .introlanding .title,
  .campaign.intro .column .title,
  .featuresbanner>.title,
  .benefits>.title,
  .import.stuff .title,
  .pricingintro .title {
    font-size: 30px;
  }

  .featuresbanner .features-navigation {
    justify-content: flex-start;
    overflow: auto;
    max-width: 100vw;
  }
}

@media (max-width: 480px) {
  .import.stuff .img {
    display: none;
  }
}

@media (max-width: 400px) {

  .introlanding .title,
  .campaign.intro .column .title,
  .featuresbanner>.title,
  .benefits>.title,
  .import.stuff .title,
  .pricingintro .title {
    font-size: 23px;
  }
}

@media (max-width: 300px) {

  .introlanding .title,
  .campaign.intro .column .title,
  .featuresbanner>.title,
  .benefits>.title,
  .import.stuff .title,
  .pricingintro .title {
    font-size: 18px;
  }
}


.feedbacktop {
  overflow: hidden;
  max-width: 1250px;
  margin: 120px auto 50px auto;
  width: 80vw;

  .title {
    position: relative;
    border-bottom: 2px solid rgba(9, 50, 58, 0.0862745098);
    display: flex;
    justify-content: space-between;
    order: -2;

    span {
      padding-left: 20px;
      margin: 15px 0;
      text-align: center;
      font-weight: bold;
      font-size: 36px;
      color: #09323a;
    }
  }
  .boards{
    margin: 10px 0;
    a{
      margin: 0 20px;
      padding: 10px 0;
      cursor: pointer;
      border-bottom: 3px solid transparent !important;
      font-weight: 600;
      font-size: 18px;
      color: rgba(9, 50, 58, 0.4509803922);
      text-align: center;
      line-height: 25px;
      text-transform: capitalize;
      &:hover{
        color: #09323a;
      }
    }
  }
}