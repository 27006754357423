.basic,
.three-columns {
  padding: 100px 10vw 100px 10vw;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  &.blue {
    background: linear-gradient(0deg, #ffffff, #e8fcff);
  }
  > .title {
    text-align: center;
    font-size: 32px;
    margin-bottom: 15px;
    color: #09323a;
    font-weight: bold;
    line-height: 29px;
  }
  .description {
    text-align: center;
    opacity: 0.5960054;
    width: 271px;
    color: #09323a;
    font-size: 15px;
    font-weight: 400;
    line-height: 23px;
  }
}
.basic {
  > .title {
    width: 100%;
  }
  > .description {
    width: 100%;
  }
}
.selector.affiliate {
  width: 400px;
  .top {
    width: 200px;
  }
}
.explanations {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  .explanation {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: fit-content;
    padding: 20px;
    > h2,
    h4 {
      text-align: left;
    }

    div.ico {
      font-weight: bold;
      font-size: 50px;
      &.points {
        color: white;
        margin-bottom: 20px;
        border-radius: 20px;
        display: flex;
        text-align: center;
        justify-content: flex-start;
        align-items: flex-start;
        width: 80px;
        height: 80px;
        border-radius: 40px;
        padding: 10px;
        align-items: center;
        justify-content: center;
        font-size: 30px;
      }
    }
  }
}
.newsletter.affiliate div.text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  .newsTitle {
    width: 80%;
    margin-bottom: 40px;
    text-align: center;
  }
  .description {
    text-align: center;
    opacity: 0.5960054;
    color: #09323a;
    font-size: 15px;
    font-weight: 400;
    line-height: 23px;
    width: 80%;
  }
}

@media (max-width: 800px) {
  .three-columns {
    padding: 60px 2vw 20px 2vw;
  }
}
