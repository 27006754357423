@font-face {
  font-family: 'Gilroy';
  font-weight: 500;
  font-display: swap;
  font-style: normal;
  src:
    url('/fonts/Gilroy-Medium/font.woff2') format('woff2'),
    url('/fonts/Gilroy-Medium/font.woff') format('woff');
}

@font-face {
  font-family: 'Gilroy';
  font-display: swap;
  font-weight: bold;
  font-style: normal;
  src:
    url('/fonts/Gilroy-Bold/font.woff2') format('woff2'),
    url('/fonts/Gilroy-Bold/font.woff') format('woff');
}

@font-face {
  font-family: 'Gilroy';
  font-display: swap;
  font-weight: 600;
  font-style: normal;
  src:
    url('/fonts/Gilroy-SemiBold/font.woff2') format('woff2'),
    url('/fonts/Gilroy-SemiBold/font.woff') format('woff');
}

@font-face {
  font-family: 'Gilroy';
  font-display: swap;
  font-weight: 400;
  font-style: normal;
  src:
    url('/fonts/Gilroy-Regular/font.woff2') format('woff2'),
    url('/fonts/Gilroy-Regular/font.woff') format('woff');
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  //background: $standardborder;
  border-radius: 3px;
  margin: 8px;
}

::-webkit-scrollbar-thumb {
  background: #e1e7e9;
  border-radius: 4px;
}

html,
body {
  scroll-behavior: smooth;
  padding: 0;
  margin: 0;
  font-family: 'Gilroy', Arial, sans-serif;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body.modal-open {
  overflow: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.articleprogress {
  width: 100%;
  height: 5px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 6;
}

.subheadercover {
  max-height: 300px;
  object-fit: cover;
  border-radius: 22px;
  height: auto;
  width: 80vw;
  margin: 20px 10vw 40px 10vw;
}

.subheader {
  width: 60vw;
  margin-top: 120px;
  margin-bottom: 25px;
  margin-left: 20vw;
  margin-right: 20vw;
  border-bottom: 1px solid #09323a16;
}
.subheader.workspace {
  max-width: 700px;
  margin: auto;
}

.subheader.guide {
  width: 80vw;
  margin-left: 10vw;
  margin-right: 10vw;
}

.subheader.guide .subtitle {
  display: none;
}

.zapier .title {
  height: fit-content;
  color: #09323a;
  font-family: 'Gilroy';
  font-size: 48px;
  font-weight: bold;
  line-height: 56px;
  margin-bottom: 36px;
  text-transform: capitalize;
}

.subheader .title {
  height: fit-content;
  color: #09323a;
  font-family: 'Gilroy';
  font-size: 48px;
  font-weight: bold;
  line-height: 56px;
  margin-bottom: 36px;
  text-transform: capitalize;
}

.subheader .subtitle {
  display: flex;
  margin-bottom: 25px;
  max-width: 100%;
}

.subheader .subtitle span {
  height: 24px;
  color: #09323a4d;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  text-align: right;
  text-transform: uppercase;
}

.subheader .subtitle .author span {
  color: #09323a;
}

.subheader .subtitle .created {
  margin-left: auto;
  margin-right: 16px;
}

.lastarticles {
  display: flex;
  margin-top: 165px;
  margin-bottom: 100px;
  max-width: 80vw;
  width: 80vw;
  margin-left: 10vw;
  margin-right: 10vw;
  display: flex;
  justify-content: center;
}

.lastarticles .leftsection {
  width: 50%;
  height: 500px;
  margin-right: 39px;
  position: relative;
  background-size: cover;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  background-position: center;
}

.lastarticles .leftsection img {
  width: 100%;
  height: 70%;
  object-fit: cover;
}

.lastarticles .leftsection .rectangle {
  position: absolute;
  width: calc(100% - 50px);
  left: 0;
  right: 0;
  bottom: 25px;
  padding: 25px;
  margin: auto;
  background: #09323a;
  border-radius: 6px;
}

.lastarticles .leftsection .rectangle>.title {
  color: white;
  font-size: 24px;
  font-weight: bold;
  line-height: 34px;
  width: 100%;
  text-transform: capitalize;
}

.lastarticles .leftsection .rectangle .subtitle {
  margin-top: 15px;
  margin-bottom: 0px;
}

.lastarticles .leftsection .rectangle .subtitle>span {
  color: rgba(255, 255, 255, 0.404);
}

.lastarticles .leftsection .rectangle .subtitle>span span {
  color: rgba(255, 255, 255);
}

.lastarticles .rightsection {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-left: 39px;
  height: 500px;
}

.lastarticles .rightsection .single {
  margin-bottom: 35px;
  border-bottom: 1px solid #09323a16;
}

.lastarticles .rightsection .single .title {
  font-weight: bold;
  font-size: 36px;
  color: #09323a;
  line-height: 40px;
  margin-bottom: 30px;
  text-transform: capitalize;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.lastarticles .rightsection .single .resume {
  font-weight: 500;
  font-size: 15px;
  color: #09323a93;
  margin-top: 20px;
  line-height: 20px;
  margin-bottom: 25px;
}

.introguides {
  margin-top: 120px;
  margin-bottom: 50px;
  width: 80vw;
  margin-left: 10vw;
  position: relative;
}

.introguides {
  margin-top: 120px;
  margin-bottom: 50px;
  width: 80vw;
  margin-left: 10vw;
  position: relative;
}

.introguides img {
  width: 100%;
}

.introguides .text {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 40px;
  bottom: 0;
  margin: auto;
  height: fit-content;
}

.introguides .title {
  color: #09323a;
  font-size: 32px;
  font-weight: bold;
  line-height: 29px;
  margin-bottom: 15px;
}

.introguides .description {
  width: 60%;
  color: #09323a99;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
}

.metrics.loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 150px;
}

.metrics.loading span {
  font-size: 16px;
  font-weight: 500;
  margin: 20px;
}

.loader {
  animation: rotate 2s infinite;
  height: 50px;
  width: 50px;
}

.loader:before,
.loader:after {
  border-radius: 50%;
  content: '';
  display: block;
  height: 20px;
  width: 20px;
}

.loader:before {
  animation: ball1 1s infinite;
  background-color: #fd3f67;
  box-shadow: 30px 0 0 #fcb504;
  margin-bottom: 10px;
}

.loader:after {
  animation: ball2 1s infinite;
  background-color: #0ac9ef;
  box-shadow: 30px 0 0 #97bf0d;
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
  }

  50% {
    -webkit-transform: rotate(360deg) scale(1.2);
    -moz-transform: rotate(360deg) scale(1.2);
  }

  100% {
    -webkit-transform: rotate(720deg) scale(0.8);
    -moz-transform: rotate(720deg) scale(0.8);
  }
}

@keyframes ball1 {
  0% {
    box-shadow: 30px 0 0 #f8b334;
  }

  50% {
    box-shadow: 0 0 0 #f8b334;
    margin-bottom: 0;
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
  }

  100% {
    box-shadow: 30px 0 0 #f8b334;
    margin-bottom: 10px;
  }
}

@keyframes ball2 {
  0% {
    box-shadow: 30px 0 0 #97bf0d;
  }

  50% {
    box-shadow: 0 0 0 #97bf0d;
    margin-top: -20px;
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
  }

  100% {
    box-shadow: 30px 0 0 #97bf0d;
    margin-top: 0;
  }
}

.table tbody tr:nth-child(odd) {
  background-color: #f2f2f2;
}

.table tbody tr:hover {
  background-color: #ddd;
}

.table thead h5 {
  font-size: 24px;
  margin: 10px;
}

.table tbody td {
  position: relative;
  padding: 0px 15px;
}

.table tbody td svg {
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.table td p {
  text-align: left;
  width: 100%;
  color: #09323a;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
}

.clients {
  background: #f8fafb;
  /*linear-gradient(0deg, #FFFFFF, #E8FCFF);*/
  margin: 100px 0;
  width: 100%;
  padding: 40px 0;
}

.clients .title {
  padding: 0 10vw;
  margin-top: 0px;
  color: #90a3a6;
  font-size: 30px;
  font-weight: bold;
  font-style: normal;
  line-height: 54px;
  text-align: center;
}

.clients .logos {
  padding: 0 10vw;
  display: flex;
  margin: 30px 10%;
  justify-content: center;
  width: 80%;
  flex-wrap: wrap;
}

.clients .logos img {
  margin: 15px 20px;
  height: 30px;
  filter: grayscale(1);
  opacity: 0.5;
}

.compare {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.compare .block {
  display: flex;
  flex-wrap: wrap;
  box-shadow: 4px 25px 45px 0px rgba(112, 135, 139, 0.1);
  border-radius: 15px;
  overflow: hidden;
  margin: 20px 0;
  z-index: 2;
  width: fit-content;
  justify-content: center;
}

.compare .block .logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
  min-width: 150px;
  background: #09323a;
  border-radius: 15px;
  padding-top: 20px;
}

.compare .block .logo span {
  color: white;
}

.compare .block .logo img {
  height: 30px;
}

.compare .block .feature {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
  min-width: 150px;
  position: relative;
  padding-top: 20px;
  width: auto;
}

.compare .block .feature.equal span:first-child {
  font-weight: bold;
  font-size: 40px;
}

.compare .block .feature.equal span:last-child {
  margin-top: 0;
  height: 20px;
}

.compare .block div.feature:first-child:after {
  display: none;
}

.compare .block div.feature:nth-child(2):after {
  display: none;
}

.compare .block div.feature.equal:after {
  content: '=';
}

.compare .block .feature::after {
  position: absolute;
  pointer-events: none;
  content: '+';
  z-index: 2;
  height: 20px;
  width: 20px;
  top: 0;
  margin: auto;
  bottom: 20px;
  left: -12px;
  font-size: 30px;
  color: #09323a;
}

.compare .block .feature img {
  height: 30px;
}

.compare .block span {
  text-align: center;
  color: #09323a;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  margin-top: 8px;
  align-items: center;
  display: flex;
  height: 43px;
}

.import.intro {
  overflow: hidden;
  max-width: 1250px;
  margin: 200px auto 50px auto;
  width: 80vw;
  position: relative;

  .reviewslanding {
    align-items: flex-start;
  }

  >img {
    width: 100%;
    height: auto;
    max-width: 500px;
    margin-left: auto;
  }
}

.import.intro .demo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 20px;
  margin: 0 20px;
}

.import.intro .demo:hover {
  background: #f7f9fb;
  border-radius: 20px;
}

.import.intro .title {
  margin-top: 0px;
  color: #09323a;
  font-size: 30px;
  font-weight: bold;
  font-style: normal;
  line-height: 40px;
  text-align: left;
  margin-bottom: 25px;
}

.import {
  display: flex;
}

.import.stuff {
  display: flex;
  width: 100%;
  padding: 150px 10vw;
  margin: 0;
  background-image: linear-gradient(-29deg, #06252b 0%, #09323a 61%);
}

.import.stuff .column {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  max-width: 600px;
  min-width: calc(50%);
}

.import.stuff .column svg {
  padding: 40px;
  max-width: 580px;
}

.import.stuff .title {
  color: white;
  max-width: 400px;
}

.import.stuff h1,
.import.stuff .imports {
  /*width: 400px;*/
}

.import.stuff .description {
  color: #ffffff8f;
  max-width: 400px;
}

.import .column {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.import .column video {
  transform: scale(1.3);
  width: 100%;
}

.import .column .competence {
  min-width: 200px;
}

.import .column .edworking {}

.import .column .transfer {
  margin: 20px;
  width: calc(100% - 40px);
  height: auto;
}

.table {
  width: 100%;
}

.compare,
.import {
  margin: 150px 0;
  width: 80vw;
  margin-left: 10vw;
  position: relative;
}

.multicolors {
  position: relative;
  margin: 60px 0;
  height: 4px;
  width: 100%;
  max-width: 80vw;
  background: linear-gradient(90deg,
      #fcb504 0%,
      #fcb504 25%,
      #fd3f67 25%,
      #fd3f67 50%,
      #0ac9ef 50%,
      #0ac9ef 75%,
      #05d6a2 75%,
      #05d6a2 100%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.multicolors::after {
  position: absolute;
  pointer-events: none;
  content: 'VS';
  height: 25px;
  width: 50px;
  text-align: center;
  background: white;
  font-weight: bold;
  font-size: 25px;
  z-index: 2;
  top: 0;
  margin: auto;
  bottom: 0px;
  left: 0px;
  right: 0px;
  color: #09323a;
}

.import .title {
  margin-top: 0px;
  color: #09323a;
  font-size: 30px;
  font-weight: bold;
  font-style: normal;
  line-height: 40px;
  text-align: left;
  margin-bottom: 25px;
}

.import .description {
  margin-top: 0px;
  color: #09323a73;
  line-height: 30px;
  letter-spacing: 0;
  font-size: 16px;
  text-align: left;
  font-weight: normal;
  font-style: normal;
  margin-bottom: 50px;
}

.compare .title {
  margin-top: 0px;
  color: #09323a;
  font-size: 40px;
  font-weight: bold;
  font-style: normal;
  line-height: 54px;
  text-align: center;
}

.compare .description {
  margin-top: 0px;
  color: #09323a73;
  line-height: 30px;
  letter-spacing: 0;
  font-size: 18px;
  text-align: center;
  font-weight: normal;
  font-style: normal;
  margin-bottom: 50px;
}

.similararticles {
  display: flex;
  flex-direction: column;
  max-width: 80vw;
  width: 80vw;
  margin-left: 10vw;
  margin-right: 10vw;
  display: flex;
  justify-content: center;
}

.similararticles .sectiontop {
  position: relative;
  border-bottom: 2px solid #09323a16;
  display: flex;
  justify-content: space-between;
}

.similararticles .sectiontop.startups::before {
  content: '';
  position: absolute;
  bottom: -6px;
  height: 6px;
  width: 20%;
  background-color: #fcb911;
}

.similararticles .sectiontop.remotework::before {
  content: '';
  position: absolute;
  bottom: -6px;
  height: 6px;
  width: 20%;
  background-color: #12d8a7;
}

.similararticles .sectiontop.edworking::before {
  content: '';
  position: absolute;
  bottom: -6px;
  height: 6px;
  width: 20%;
  background-color: #16ccf0;
}

.similararticles .sectiontop.podcast::before {
  content: '';
  position: absolute;
  bottom: -6px;
  height: 6px;
  width: 20%;
  background-color: #fd3f67;
}

.title {
  margin: 0;
}

.similararticles .sectiontop span {
  display: flex;
  text-transform: capitalize;
  justify-content: space-between;
  padding-bottom: 38px;
}

.single:hover .title {
  text-decoration: underline;
}

.similararticles .sectiontop a {
  display: flex;
  justify-content: space-between;
  padding-bottom: 38px;
}

.similararticles .sectiontop span.title {
  font-weight: bold;
  font-size: 30px;
  color: #09323a;
}

.similararticles .sectiontop a.more {
  font-weight: bold;
  font-size: 14px;
  color: #09323a;
  align-items: center;
}

.similararticles .articles {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 55px;
  margin-bottom: 80px;
  margin-left: -30px;
  margin-right: -30px;
  width: calc(100% + 60px);
}

.similararticles .articles .single:last-child {
  margin-right: 0px;
}

header .menu div.dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  border-radius: 20px;
  min-width: 160px;
  box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.06);
  z-index: 1;
  flex-direction: column;
  top: 40px;
  left: -95px;
  max-width: 215px;
  align-items: flex-start;

  &.extensive {
    flex-direction: row;
    width: fit-content;
    flex-wrap: wrap;
    max-width: 800px;
    width: 800px;
    padding: 25px;
    left: -50vw;
    right: -50vw;
    margin: auto;

    &.features {
      >a {
        max-height: 180px;

        >img {
          margin: 0px 10px 105px 0;
        }
      }
    }

    &.short {
      display: flex;

      >a {
        max-height: 80px;

        >img {
          margin: 0px 10px 40px 0;
          height: 25px;
          width: auto;
          max-width: 50px;
        }
      }
    }

    >a {
      width: 250px;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      max-height: 180px;
      max-height: 90px;
      margin: 0;
      padding: 10px;

      &:hover {
        background: #f7f9fb;
        border-radius: 8px;
      }

      >img {
        margin: 0px 10px 48px 0;
        height: 25px;
        width: auto;
        max-width: 50px;
      }

      >span {
        width: calc(100% - 60px);

        &.title {
          font-size: 18px;
          margin-bottom: 10px;
        }

        &.description {
          color: #09323a60;
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
  }
}

header .menu div.dropdown-content a {
  color: black;
  padding: 12px 0;
  text-decoration: none;
  display: flex;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-style: normal;
  font-size: 15px;
  letter-spacing: 0;
  color: #09323acc;
  margin: 0 20px;
}

header .menu div.dropdown-content a:hover {
  color: #02bcea;
}

.dropdown:hover .dropdown-content {
  display: flex !important;
}

.similararticles .articles .single {
  display: flex;
  flex-direction: column;
  margin-right: 30px;
  margin-left: 30px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(25% - 60px);
  min-width: 150px;
  margin-bottom: 30px;
}

.similararticles .articles .single .a {
  width: 100%;
}

.similararticles .articles .single .subtitle {
  margin-top: 20px;
}

.similararticles .articles .single .subtitle .created {
  margin-right: 0px;
}

.similararticles .articles .single .cover {
  height: 170px;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 30px;
  margin-top: 0px;
}

.similararticles .articles .single .title {
  font-weight: bold;
  font-size: 18px;
  color: #09323a;
  line-height: 20px;
  text-transform: capitalize;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.similararticles .articles .single .resume {
  font-weight: 500;
  font-size: 15px;
  color: #09323a93;
  margin-top: 20px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.index {
  float: left;
  display: flex;
  flex-direction: column;
  max-width: 20vw;
  left: 10vw;
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 10px;
  margin: 0;
  position: -webkit-sticky;
  position: sticky;
  top: 120px;
  border: 1px solid #09323a16;
}

.index.plan {
  display: flex;
  margin-top: 30px;
  margin-bottom: 30px;
  min-width: 320px;
  flex-direction: column;
  left: 0vw;
  padding-left: 40px;
  padding-right: 40px;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 10px;
  position: -webkit-sticky;
  position: sticky;
  top: 120px;
  border: 1px solid #09323a16;
}

.index.plan .title {
  margin-top: 5px;
  margin-bottom: 0px;
  line-height: 32px;
}

.index .clickable {
  display: flex;
  align-items: center;
}

.index .bar {
  display: flex;
  flex-direction: column;
  width: 2.5px;
  height: 40px;
  margin-left: -42px;
  background-color: #16ccf0;
  position: absolute;
  margin-bottom: 30px;
}

.index .elements {
  display: flex;
  flex-direction: column;
  width: 100%;
  cursor: pointer;
  font-size: 14px;
}

.index .element {
  display: flex;
  flex-direction: column;
  margin: 0px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.index h1,
.index .title {
  font-weight: bold;
  font-size: 30px;
  color: #09323a;
  border-bottom: 3px solid #09323a16;
  padding-bottom: 20px;
}

.index .title {
  margin: 20px 0 10px 0;
}

.index .elements {
  padding: 0;
}

.index h4,
.index .element {
  font-weight: 600;
  line-height: 20px;
  color: #09323a;
  font-size: 16px;
  /*36px*/
  padding-bottom: 20px;
}

.index li {
  margin: 0;
}

.page {
  flex-direction: row;
}

.articlefull {
  display: flex;
  flex-direction: column;
  max-width: 60vw;
  width: 60vw;
  left: 10vw;
  right: 10vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.article {
  display: flex;
  flex-direction: column;
  max-width: 50vw;
  width: 50vw;
  margin-left: 10vw;
  margin-right: 10vw;
  display: flex;
  margin: 0px;
  padding-bottom: 150px;
}

.article .imagebox {
  display: flex;
  margin: 0px;
  align-items: center;
  justify-content: center;
}

.article .cover.default {
  transform: rotate(180deg);
  max-height: 300px;
  object-fit: cover;
  width: 60vw;
  margin-top: 20px;
  margin-bottom: 40px;
  border-radius: 22px;
}

.article .quill {
  min-height: 90vh;
  width: 60vw;
  margin-left: 20vw;
  margin-right: 10vw;
  margin-left: 10vw;
}

.article .quill .ql-editor {
  overflow: visible;
  padding: 0;
}

.article .video {
  width: 100%;
  height: auto;
  margin: 25px 0;
}

section.standard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 75px;
  margin: 115px auto 0 auto; //75px auto 0 auto; //topbanner
  z-index: 3;
  padding: 50px 0;
  width: 80vw;
}

section.standard ul {
  width: 100%;
  word-break: break-all;
}

.subtitle {
  display: flex;
}

.subtitle .author span {
  color: #09323a;
}

.subtitle span {
  height: 24px;
  color: #09323a4d;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  text-align: right;
  text-transform: uppercase;
}

.subtitle .author {
  text-align: left;
}

.subtitle .created {
  margin-left: auto;
  margin-right: 16px;
  text-align: right;
}

.article .artimage {
  margin-top: 40px;
  margin-bottom: 60px;
}

.article p {
  font-size: 18px;
  line-height: 30px;
  color: rgba(9, 50, 58, 0.6);
  letter-spacing: 0;
  margin: 0;
  margin-top: 12px;
}

.article ul {
  margin-top: 24px;
}

.article ol {
  margin-top: 24px;
}

.article li {
  font-size: 18px;
  line-height: 30px;
  color: rgba(9, 50, 58, 0.6);
  letter-spacing: 0;
  margin: 0;
  margin-top: 0px;
}

.article pre {
  margin-bottom: 5px;
  margin-top: 5px;
  white-space: pre-wrap;
  border-radius: 3px;
  padding: 5px 10px;
}

.article pre.ql-syntax {
  background-color: #23241f;
  color: #f8f8f2;
  overflow: visible;
}

.article .quill .ql-container {
  font-family: 'Gilroy', Arial, sans-serif !important;
  font-size: 15px;
}
/*
.article h1 {
  margin-top: 38px;
  font-size: 32px;
  line-height: 40px;
  color: #09323a;
  margin-bottom: 10px;
}

.article h2 {
  margin-top: 10px;
  line-height: 28px;
  font-size: 24px;
  color: #09323a;
  margin-bottom: 10px;
  scroll-margin: 120px;
}

.article h3 {
  margin-top: 38px;
  margin-bottom: 0px;
  line-height: 24px;
  font-size: 22px;
  color: #09323a;
  scroll-margin: 120px;
}

.article h4 {
  position: relative;
  width: 100%;
  color: #09323a;
  font-size: 20px;
  font-weight: bold;
}
*/
.article a {
  color: #23c49b;
}

.article a:hover {
  text-decoration: underline;
}

/*
.article .body{
  width: 60vw;
  max-width: 700px;
}*/

.article img {
  width: 100%;
  border-radius: 20px;
  margin: 15px 0;
  height: auto;
}

.article h4::before {
  content: null;
  z-index: 1;
  position: absolute;
  width: 6px;
  top: 0px;
  bottom: 0px;
  left: -34px;
  background: linear-gradient(180deg,
      #fcb504 0%,
      #fcb504 25%,
      #fd3f67 25%,
      #fd3f67 50%,
      #0ac9ef 50%,
      #0ac9ef 75%,
      #05d6a2 75%,
      #05d6a2 100%);
}

.tryedworking {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100vw;
  height: 300px;
  background: linear-gradient(0deg, #ffffff, #f0f6f7);
}

.tryedworking img {
  position: absolute;
  width: 60%;
}

.tryedworking span {
  z-index: 1;
  width: 368px;
  color: #09323a;
  font-size: 27px;
  font-weight: bold;
  line-height: 49px;
  text-align: center;
}

.tryedworking div {
  margin: 20px;
  z-index: 1;
}

.blueButton {
  display: flex;
  padding: 0 25px;
  min-height: 44px;
  max-height: 44px;
  border-radius: 22px;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-style: normal;
  background: #0fc8ef;
  color: white;
  cursor: pointer;
  z-index: 0;
  transition: 0.3s;
}

.blueButton:hover {
  background: #02bbe2;
}

footer {
  overflow: hidden;
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 80px;
  position: absolute;
  width: 100%;
  max-width: 100vw;
  margin: 0;
  border-top: 1px solid rgba(223, 228, 229, 0.658824);
  padding: 0;
}

footer .contact {
  font-weight: 500;
  font-style: normal;
  font-size: 15px;
  color: #47656b8c;
  letter-spacing: 0;
}

footer .contact span {
  cursor: pointer;
  margin-left: 15px;
  color: #09323a;
  font-weight: 500;
}

footer .contact a {
  cursor: pointer;
  margin-left: 15px;
  text-decoration: none;
  color: #09323a;
  font-weight: 500;
}

footer .contact a:hover {
  color: #16daf4;
}

footer .contact span:hover {
  color: #16daf4;
}

footer .logo {
  height: 24px;
}

footer .social {
  font-weight: 500;
  font-style: normal;
  color: #47656b;
  font-size: 15px;
  letter-spacing: 0;
  display: flex;
  align-items: center;
}

footer .social img {
  cursor: pointer;
  border-radius: 14px;
  background: #ffffff;
  opacity: 1;
  border: 1px solid rgba(223, 228, 229, 0.658824);
  width: 46px;
  height: 46px;
  padding: 10px;
  margin: 5px;
}

footer .social span {
  margin-right: 20px;
}

footer .social img#dark {
  display: none;
  background: #2a3d42;
}

footer .social img#light {
  display: block;
  background: #ffffff;
  border: 1px solid #c4d0d3bf;
}

footer .social img:hover {
  background: #2a3d42;
  box-shadow: 2px 8px 10px 0 rgba(206, 213, 220, 0.1);
}

header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 85px;
  position: fixed;
  top: 0;
  width: calc(100vw - 60px);
  margin: 0 30px;
  z-index: 5;
  padding: 0;
  display: flex;
  background: transparent;
  max-width: 1250px;
  margin: auto;
  left: 0;
  right: 0;
}

header::after {
  content: '';
  z-index: -1;
  position: absolute;
  bottom: 0px;
  left: -1000px;
  right: -1000px;
  top: 0px;
  background: #ffffffe0;
  backdrop-filter: blur(3px);
}

header.mediaheader::before {
  content: '';
  z-index: 0;
  position: absolute;
  left: -10vw;
  right: -10vw;
  bottom: 0;
  height: 3px;
  background: linear-gradient(90deg,
      #fcb504 0%,
      #fcb504 25%,
      #fd3f67 25%,
      #fd3f67 50%,
      #0ac9ef 50%,
      #0ac9ef 75%,
      #05d6a2 75%,
      #05d6a2 100%);
}

header .menu {
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
}

header .menu .signupsmall {
  display: none;
}

header .menu>div,
header .menu>a {
  cursor: pointer;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-style: normal;
  font-size: 15px;
  letter-spacing: 0;
  color: #09323acc;
  margin: 0 20px;
  transition: 0.3s;
  -webkit-transition: 0.3s;

  >a {
    display: flex;
  }
}

/*
header .menu a:hover {
  color: #16DAf4;
}*/
header .menu .language {
  cursor: pointer;
}

header .menu div.signin {
  height: 44px;
  max-height: 44px;
  padding: 1px 0px 0px 0px;
  width: 112px;
  border-radius: 22px;
  font-weight: 600;
  font-style: normal;
  background: #f2f6f7;
}

header .menu div.signin.mobile {
  display: none;
}

header .menu div.signin:hover {
  color: #09323a;
  filter: brightness(0.95);
}

header .menu a.signup {
  color: #fff;
  background: #0fc8ef;
  border-radius: 22px;
  height: 44px;
  width: 162px;
  max-height: 44px;
  padding: 0px 10px;
  font-weight: 600;
  font-style: normal;

  >div {
    text-align: center;
  }
}

header .menu div.signup:hover {
  background: #02bbe2;
}

header .menu div.signupsmall {
  color: #fff;
  background: #0fc8ef;
  border-radius: 22px;
  height: 44px;
  width: 85px;
  max-height: 44px;
  padding: 1px 0px 0px 0px;
  font-weight: 600;
  font-style: normal;
}

header .menu div.signupsmall:hover {
  background: #02bbe2;
}

.unavailable {
  margin: auto;
  left: 0;
  right: 0;
  top: 100px;
  position: absolute;
  width: 400px;
  height: 50px;
  text-align: center;
  border: 1px solid #dfe4e5a8;
  box-shadow: 7px 10px 50px 0 #ced5dc26;
  height: fit-content;
  padding: 50px;
  border-radius: 34px;
}

.unavailable a {
  text-decoration: underline;
  color: #47a1ed;
}

.unavailable h1 {
  color: #09323a;
  font-weight: 600;
}

.unavailable h4 {
  color: #09323a80;
  font-weight: 400;
}

.unavailable .logo {
  width: 200px;
}
/*
.sharing {
  display: flex;
  flex-direction: column;
  position: fixed;
  align-items: center;
  right: 0;
  bottom: 150px;
}
*/
.sharing span {
  color: #09323a;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.sharing img {
  width: 40px;
  height: 40px;
  margin: 5px 20px;
  transition: 0.5s;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 4px 15px 35px 10px rgb(71 101 107 / 10%);
  border: 1px solid rgba(223, 228, 229, 0.658824);
}

.sharing img:hover {
  width: 42px;
  height: 42px;
  margin: 4px 19px;
}

#menu1 {
  display: flex;
}

#menumobile {
  display: none;
  /*
  > p{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 54px;
    width: calc(100% - 120px);
    padding: 0 60px;
    margin: 0;
    &:hover{
      background: white;
      color: #16DAf4;
    }
  }
  .dropdown-mobile{
    display: flex;
    flex-direction: column;
    height: auto;
    &:hover{
      background: white;
      color: #09323A;
    }

    > a {
      display: flex;
      flex-direction: column;


    }

  }*/
}

header .menu div#menumobile.menumobile {
  position: fixed;
  background: white;
  flex-direction: column;
  justify-content: flex-start;
  top: 115px;
  border: 1px solid rgb(154 175 180 / 30%);
  width: 100%;
  left: 0;
  margin: 0;
  max-height: calc(100vh - 115px);
  overflow-y: auto;

  &:hover {
    color: #09323a;
  }

  .category {
    display: flex;
    flex-direction: column;
    width: 100%;

    /*overflow: hidden;*/
    .subcategory {
      flex-direction: column;
      max-height: 0;
      transition: 0.5s;

      &.show {
        max-height: 1000px;
      }

      &.hidden {
        .element {
          display: none;
        }
      }

      .element {
        padding-left: 100px;
      }
    }
  }

  .element {
    height: 54px;
    min-height: 54px;
    width: 100%;
    padding-left: 60px;
    flex-wrap: wrap;
    align-content: center;
    padding-right: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    img {
      transition: 0.5s;
      margin: 0 !important;
    }

    &:hover {
      color: #16daf4;
      background: #f7f9fb;
    }
  }
}

#menuBurger {
  display: none;
}

.comparesite {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80vw;
  margin: 0px auto;
  margin-top: 140px; //100px; //topbanner
  max-width: 1250px;
}

.comparesite .sectiontop {
  position: relative;
  border-bottom: 2px solid #09323a16;
  display: flex;
  justify-content: space-between;
  order: -2;
  width: 100%;
  font-weight: bold;
  font-size: 36px;
  color: #09323a;
  display: flex;
  text-transform: capitalize;
  justify-content: space-between;
  padding-bottom: 38px;

  &::before {
    content: "";
    position: absolute;
    bottom: -6px;
    height: 6px;
    width: 20%;
  }

  &.socialmedia::before {
    background-color: #fcb911;
  }

  &.writing::before {
    background-color: #12d8a7;
  }
}

.comparesite h1 {
  text-align: center;
  font-size: 40px;
  max-width: 700px;
  width: 100%;
}

.comparesite>.description {
  margin-top: 0px;
  color: #90a3a6;
  line-height: 22px;
  letter-spacing: 0;
  font-size: 17px;
  text-align: center;
  font-weight: normal;
  font-style: normal;
  margin-bottom: 50px;
  justify-content: center;
  align-items: flex-start;
  max-width: 500px;
  width: 100%;
}

.competences {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 100px;
  justify-content: center;
}

.comparesite .blueButton {
  margin-bottom: 50px;
}

.competence {
  background: #fff;
  margin: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 18px;
  min-width: 200px;
  width: calc(25% - 40px);
  position: relative;
  box-shadow: 2px 4px 3px 0 rgb(206 213 220 / 60%);
  cursor: pointer;
  transition: 0.3s;
}

.competence:hover {
  background: #f7f9fb;
}

.competence span.title {
  text-align: left;
  font-size: 20px;
  font-weight: bold;
  margin: 0;
}

.competence span.description {
  font-weight: 500;
  font-size: 15px;
  color: #09323a93;
  text-align: left;
  margin-top: 10px;
  line-clamp: 4;
  -webkit-line-clamp: 4;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.competence img {
  position: absolute;
  right: 10px;
  bottom: 10px;
  opacity: 0.2;
  width: 80px;
}

.reviews {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-around;
  width: 80vw;
  padding: 0;
  margin: 100px 10vw 200px 10vw;
}

.reviews>h4 {
  opacity: 0.45275298;
  width: 100%;
  padding: 0 28%;
  margin-bottom: 80px;
  height: 44px;
  color: #09323a;
  font-size: 15px;
  font-weight: 500;
  line-height: 28px;
  text-align: center;
}

.reviews>h2 {
  width: 100%;
  height: 48px;
  color: #09323a;
  font-size: 30px;
  font-weight: bold;
  line-height: 48px;
  text-align: center;
}

.reviews>div {
  max-width: 294px;
  margin: 30px;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.reviews>div img {
  height: 292px;
}

.reviews>div .title {
  margin: 0;
  margin-top: 15px;
  height: 24px;
  color: #09323a;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  width: 100%;
  text-align: left;
}

.reviews>div .company {
  font-size: 18px;
  line-height: 26px;
  margin: 0;
  margin-bottom: 20px;
  margin-top: 5px;
  color: #c26edc;
  font-weight: 600;
  font-size: 14px;
  font-weight: 400;
  width: 100%;
  text-align: left;
}

.reviews>div .description {
  margin: 0;
  text-align: left;
  opacity: 0.49906993;
  width: 100%;
  color: #09323a;
  font-weight: 500;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
}

.cls-2 {
  fill: white;
}

.cls-1 {
  fill: #fff;
}

.cls-3 {
  fill: #dbdbdb;
}

.cls-4,
.cls-2,
.cls-5 {
  isolation: isolate;
}

.cls-6 {
  fill: #05d6a2;
}

.cls-7 {
  fill: #faffff;
}

.cls-8 {
  fill: #ffefdb;
}

.cls-9 {
  stroke: #0ac9ef;
  stroke-width: 3px;
}

.cls-9,
.cls-10,
.cls-11 {
  fill: none;
}

.cls-9,
.cls-11 {
  stroke-miterlimit: 10;
}

.cls-12 {
  fill: #002936;
}

.cls-10 {
  stroke: #002936;
  stroke-linejoin: round;
  stroke-width: 10px;
}

.cls-13 {
  opacity: 0.53;
}

.cls-14 {
  fill: #ebeced;
}

.cls-15 {
  fill: #dde0e0;
}

.cls-11 {
  stroke: #fff;
  stroke-dasharray: 0 0 19.65 19.65;
  stroke-width: 4.89px;
}

.cls-16 {
  opacity: 0.62;
}

.cls-17 {
  fill: #0ac9ef;
}

.cls-5 {
  fill: #fb8c72;
}

.cls-18 {
  fill: #fcb504;
}

.cls-19 {
  fill: #fd3f67;
}

@media (max-width: 950px) {
  .compare .block .feature img {
    height: 25px;
  }

  .compare .block span {
    font-size: 14px;
    height: 33px;
  }
}

@media (max-width: 1160px) {
  #menumobile {
    display: flex;
  }

  #menuBurger {
    display: flex;
  }

  header .menu>a.pricing {
    display: none;
  }

  #menu1,
  .pricing {
    display: none;
  }
}

@media (max-width: 1000px) {
  .banner.bannered {
    img {
      display: none;
    }

    .text {
      width: calc(100% - 180px);

      .subheadline {
        width: 100%;
      }
    }

    .blueButton {
      margin-left: 0px;
      min-width: 180px;
    }
  }

  .import.intro {
    flex-direction: column;
  }

  .import.intro .column {
    width: 100% !important;

    >img {
      width: 100%;
      height: auto;
    }

    .video {
      margin: auto;
    }
  }

  .import.intro .column:first-child {
    margin-bottom: 20px;
  }

  .similararticles .articles .single {
    flex-basis: calc(50% - 60px);
  }

  .similararticles .articles .single:nth-child(odd) {
    margin-right: 0;
  }

  .lastarticles {
    flex-direction: column;
  }

  .lastarticles>div {
    width: 100% !important;
  }

  .lastarticles .rightsection {
    margin-top: 40px;
    margin-left: 0;
  }
}

@media (max-width: 980px) {
  header .menu a:nth-child(1) {
    display: none;
  }

  footer div:nth-child(2) {
    display: none;
  }
}

@media (max-width: 760px) {
  .banner.bannered {
    flex-direction: column;

    .text {
      text-align: center;
      width: 100%;
    }

    .blueButton {
      min-width: 180px;
    }
  }
}

@media (max-width: 710px) {
  header .menu div.signin {
    display: none;
  }

  header .menu div.signin.mobile {
    display: flex;
  }

  .newsletter {
    width: calc(90vw - 80px);
  }

  .newsletter .image {
    display: none !important;
  }

  .newsletter .text {
    width: 100%;
  }

  .edpromotion .text {
    width: 100% !important;
  }

  .newsletter .newsTitle {
    width: 80% !important;
  }

  header .menu a:nth-child(3) {
    display: none;
  }

  .subheader .title {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 16px;
  }

  .subheader.blog {
    width: 80vw;
    margin-left: 10vw;
    margin-right: 10vw;
  }

  .article p {
    font-size: 18px;
  }

  .article li {
    font-size: 18px;
  }

  footer div:nth-child(4) {
    display: none;
  }
}

@media (max-width: 800px) {
  header .menu a:nth-child(4) {
    display: none;
  }

  footer div:nth-child(5) {
    display: none;
  }

  .import.stuff {
    flex-direction: column;
  }

  .import.stuff .column {
    width: 100% !important;
  }

  .import.stuff .column:first-child {
    margin-bottom: 60px;
  }
}

@media (max-width: 600px) {
  .competence {
    width: 100%;
  }

  .table td p {
    font-size: 12px;
  }

  .table thead h5 {
    font-size: 18px;
  }

  .table tbody td {
    padding: 0;
    width: 20%;
  }

  .table tbody td:first-child {
    width: 60%;
  }

  .table tbody td svg {
    width: 15px !important;
  }

  .subheader.blog {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 120px;
  }
}

@media (max-width: 520px) {
  .subheader .subtitle .length {
    display: none;
  }

  header .menu a:nth-child(5) {
    display: none;
  }

  header .menu a:nth-child(6) {
    display: none;
  }

  .newsletter .newsTitle {
    text-align: center;
  }

  .newsletter .description {
    text-align: center !important;
    width: auto;
  }

  .newsletter .emailinput {
    flex-wrap: wrap;
    justify-content: center;
  }

  .newsletter .emailinput .blueButton {
    margin: 10px 0;
  }

  header .menu .menumobile>div,
  header .menu .menumobile>a {
    width: calc(100%);
  }
}

@media (max-width: 480px) {
  .subheader .subtitle .length {
    display: none;
  }

  .similararticles .articles {}

  .similararticles .articles .single {
    flex-basis: calc(100% - 60px);
    margin-right: 0;
  }
}

.newsletter {
  display: flex;
  background: #fff;
  font-size: 18px;
  margin: auto;
  padding: 40px;
  border-radius: 10px;
  border: 1px solid rgba(223, 228, 229, 0.658824);
  box-shadow: 4px 25px 45px 0px rgba(112, 135, 139, 0.1);
  background-image: url(/images/PatternPromotion2.png);
  background-position: calc(100% - 40px) 40px;
  background-size: 60px;
  background-repeat: no-repeat;
  margin-bottom: 60px;
  max-width: 80vw;
  width: 80vw;
  margin-left: 10vw;
  margin-right: 10vw;
  justify-content: center;
}

.newsletter>div {
  width: 50%;
}

.newsletter div.image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.newsletter div.image img {
  width: 60%;
  max-width: 250px;
}

.newsletter div.text {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 20px;
  width: calc(50% - 20px);
}

.newsletter div.text .newsTitle {
  font-size: 32px;
  margin-bottom: 60px;
  width: 100%;
  color: #09323a;
  font-size: 24px;
  font-weight: bold;
  line-height: 29px;
}

.textInput {
  min-width: 395px;
  margin-right: 10px;
  width: 395px;
  height: 50px;
  border-radius: 25px;
  background: #ffffff;
  border: 1px solid rgba(223, 228, 229, 0.658824);
  box-shadow: 3px 6px 9px rgba(154, 175, 180, 0.08);
  font-size: 15px;
  font-family: 'Gilroy';
}

.newsletter .emailinput {
  display: flex;
  margin: 0px;
}

.newsletter .blueButton {
  margin: 0;
  border: 0;
  min-height: 50px;
  border-radius: 27px;
}

.textInput {
  max-height: 50px;
  min-width: auto;
  border: 1px solid #e8eced;
  padding: 0 15px;
}

.textInput:focus {
  background: #fff;
  outline: 0;
  border-radius: 25px;
  border: 1px solid #e8eced;
  box-shadow: 0 0 0 3px rgba(213, 225, 228, 0.4);
}

.textInput:hover {
  background: #fff;
  border-radius: 25px;
  border: 1px solid #e8eced;
  box-shadow: 0 0 0 3px rgba(213, 225, 228, 0.4);
}

.livenoti {
  display: flex;
  flex-direction: column-reverse;
  position: fixed;
  bottom: 20px;
  left: 110px;
  z-index: 100;
  width: fit-content;
  height: fit-content;
}

.livenoti .notification {
  align-items: flex-start;
  cursor: pointer;
  display: flex;
  width: 290px;
  padding: 20px;
  margin-top: 20px;
  border-radius: 16px;
  background: #09323ad9;
  font-weight: 600;
  font-style: normal;
  color: white;
  backdrop-filter: blur(2px);
}

.whiteButton:hover {
  background: #f7f9fb;
}

.whiteButton {
  display: flex;
  padding: 1px 25px 0px 25px;
  min-height: 44px;
  max-height: 44px;
  width: 220px;
  border-radius: 22px;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-style: normal;
  color: #2a3d42;
  border: 1px solid #c4d0d350;
  outline: white;
  background: #ffffff;
  margin-bottom: 59px;
  cursor: pointer;
  z-index: 2;
  transition: 0.3s;
  margin-top: 43px;
}

.livenoti .notification .logop,
.livenoti .notification img {
  justify-content: center;
  align-items: center;
  display: flex;
  min-width: 40px;
  width: 40px;
  border-radius: 10px;
  margin-right: 15px;
}

.livenoti .notification .text {
  font-weight: 500;
  font-size: 14px;
  color: #ffffffb0;
  letter-spacing: 0.16px;
  line-height: 20px;
  word-break: break-word;
}

.livenoti .notification .text .strong {
  color: #ffffff;
  font-weight: bold;
}

.edpromotion {
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 20 !important;
  background: hsla(0, 0%, 100%, 0.7);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);

  &.offer {
    align-items: center;
    justify-content: center;
  }
}

.edpromotion .newsletter {
  position: relative;
  max-height: 410px;
  max-width: 685px;
  background: #fff;
  flex-wrap: wrap;
  font-size: 18px;
  margin: auto;
  background-image: url(/images/PatternPromotion.png);
  background-position: 35px 35px;
  background-size: 60px;
  background-repeat: no-repeat;
}

.edpromotion .newsletter .text {
  margin: 40px 0 0 40px;
  width: calc(50% - 80px);
}

.edpromotion .newsTitle {
  margin-top: 20px;
  margin-bottom: 20px !important;
}

/*
.edpromotion .close{
  position: absolute;
  right: 30px;
  top: 30px;
  width: 30px;
  height: 30px;
  border-radius: 20px;
  border: 1px solid #e8eced;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.edpromotion .close img{
  width: 22px;
}
.edpromotion .close:hover {
  background: #CED5DC26;
}*/
.edpromotion .image img {
  width: 80% !important;
}

.edpromotion .title {
  margin-top: 120px;
  left: 75px;
  font-size: 26px;
  margin-bottom: 15px;
  margin-left: 65px;
  width: 287px;
  height: 66px;
  color: #09323a;
  font-size: 24px;
  font-weight: bold;
  line-height: 29px;
}

.edpromotion .emailinput {
  display: flex;
  width: 100%;
  margin: 20px 40px;
}

.edpromotion .description {
  opacity: 0.5960054;
  width: 271px;
  color: #09323a;
  font-size: 15px;
  font-weight: 400;
  line-height: 23px;
}

.allplan {
  display: flex;
  justify-content: center;
  padding-bottom: 80px;
  text-align: left;
  flex-direction: column;
  max-width: 70vw;
  margin-left: 50px;
  width: calc(70vw - 320px);
}

.allplan .features {
  margin: 80px 0;
  background: linear-gradient(0deg, #ffffff, #e8fcff);
  width: 100%;
  justify-content: space-around;
}

.allplan .features .title {
  font-size: 20px;
}

.allplan .features .description {
  width: 80%;
}

.allplan .features .feature {
  min-width: 200px;
}

.allplan .features .feature {}

.allplan .clients .logos {
  padding: 0;
  margin: 30px 3%;
  width: 94%;
}

.plan .postit p {
  margin: 0;
}

.postit strong {
  font-size: 16px;
  line-height: 32px;
  color: rgba(9, 50, 58);
  letter-spacing: 0;
  margin: 0;
}

.postit em {
  font-size: 16px;
  line-height: 30px;
  color: rgba(9, 50, 58);
  letter-spacing: 0;
  margin: 0;
}

.posts {
  display: flex;
  flex-wrap: wrap;
}

.postit {
  line-height: 1;
  text-align: center;
  max-width: calc(50% - 50px);
  min-width: calc(50% - 50px);
  margin: 25px;
  padding: 10px;
  position: relative;
  border: 1px solid #e8e8e8;
  border-top: 10px solid #fdfd86;
  font-family: 'Reenie Beanie';
  font-size: 12px;
  border-bottom-right-radius: 60px 5px;
  display: inline-block;
  background: #ffff88;
  /* Old browsers */
  background: -moz-linear-gradient(-45deg,
      #ffff88 81%,
      #ffff88 82%,
      #ffff88 82%,
      #ffffc6 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear,
      left top,
      right bottom,
      color-stop(81%, #ffff88),
      color-stop(82%, #ffff88),
      color-stop(82%, #ffff88),
      color-stop(100%, #ffffc6));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(-45deg,
      #ffff88 81%,
      #ffff88 82%,
      #ffff88 82%,
      #ffffc6 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(-45deg,
      #ffff88 81%,
      #ffff88 82%,
      #ffff88 82%,
      #ffffc6 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(-45deg,
      #ffff88 81%,
      #ffff88 82%,
      #ffff88 82%,
      #ffffc6 100%);
  /* IE10+ */
  background: linear-gradient(135deg,
      #ffff88 81%,
      #ffff88 82%,
      #ffff88 82%,
      #ffffc6 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffff88', endColorstr='#ffffc6', GradientType=1);
  /* IE6-9 fallback on horizontal gradient */
}

.postit:after {
  content: '';
  position: absolute;
  z-index: -1;
  right: -0px;
  bottom: 20px;
  width: 200px;
  height: 25px;
  background: rgba(0, 0, 0, 0.2);
  box-shadow: 2px 15px 5px rgba(0, 0, 0, 0.4);
  -moz-transform: matrix(-1, -0.1, 0, 1, 0, 0);
  -webkit-transform: matrix(-1, -0.1, 0, 1, 0, 0);
  -o-transform: matrix(-1, -0.1, 0, 1, 0, 0);
  -ms-transform: matrix(-1, -0.1, 0, 1, 0, 0);
  transform: matrix(-1, -0.1, 0, 1, 0, 0);
}

.plan canvas {
  margin: 40px 0;
}

.plan .alldevices {
  width: 100%;
  height: auto;
  max-width: 1200px;
}

.alldevices {
  width: 100%;
  height: auto;
  max-width: 1200px;
}

.zapier {
  width: 80vw;
  margin: auto;
}

.integrations {
  width: 100%;
  object-fit: cover;
  height: auto;
  max-height: 500px;
  max-width: 1200px;
}

.plan.problem {
  /*margin-top: 100px;*/
}

.plan h2 {
  font-size: 30px;
}

.plan a {
  color: #23c49b;
}

.plan a:hover {
  text-decoration: underline;
}

.plan .teammates .name {
  color: #09323a;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  text-align: center;
  margin-top: 15px;
}

.plan .teammates .role {
  font-size: 18px;
  line-height: 26px;
  margin: 0;
  color: #c26edc;
  font-weight: 600;
  font-size: 14px;
  font-weight: 400;
  width: 100%;
  margin: 5px 0;
}

.plan .teammates .description {
  margin-top: 0px;
  color: #90a3a6;
  line-height: 22px;
  letter-spacing: 0;
  font-size: 14px;
  text-align: center;
  font-weight: normal;
  font-style: normal;
  justify-content: center;
  align-items: flex-start;
}

.plan .teammates {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  margin-top: 20px;
}

.plan .teammate {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 25%;
  margin: 30px 0;
  padding: 0 10px;
}

.plan .teammate img {
  object-fit: cover;
  border-radius: 15px;
  box-shadow: rgba(206, 213, 220, 0.5) 7px 10px 50px 0px;
}

.plan p {
  font-size: 20px;
  line-height: 32px;
  color: rgba(9, 50, 58, 0.6);
  letter-spacing: 0;
  margin: 0;
  margin-top: 22px;
}

.plan ul {
  margin-top: 24px;
}

.plan h3 {
  font-size: 22px;
}

.plan li {
  font-size: 20px;
  color: rgba(9, 50, 58, 0.6);
  line-height: 28px;
  letter-spacing: 0;
  margin: 0;
}

.plan pre {
  margin-bottom: 5px;
  margin-top: 5px;
  white-space: pre-wrap;
  border-radius: 3px;
  padding: 5px 10px;
}

.cover {
  width: 100%;
  height: auto;
  margin-top: 110px;
}

.videoPlayer {
  position: relative;
  &.fit {
    cursor: pointer;
    position: relative;
    max-width: 1000px;
    margin: 150px auto;
    padding: 0;
    .play{
      position: absolute;
      margin: auto;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }
  &:hover .controlsContainer {
    opacity: 1;
  }

  video {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }

  .controlsContainer {
    transition: 0.5s;
    display: flex;
    opacity: 0;
    flex-direction: row;
    align-items: center;
    width: 80%;
    position: absolute;
    background-color: #000000b3;
    border-radius: 14px;
    bottom: 20px;
    height: 80px;
    padding: 15px;
    z-index: 10;
    left: 0;
    right: 0;
    margin: auto;

    .button {
      display: flex;
      align-items: center;
      cursor: pointer;
      justify-content: center;
      height: 50px;
      width: 50px;
      background: #ffffff1a;
      border-radius: 25px;

      &:hover {
        background: #ffffff3a;
      }
    }

    // 175 402 730
    .navigation {
      display: flex;
      color: #ffffff80;
      align-items: center;
      padding: 0 20px;
      width: calc(100% - 100px);

      span {
        width: 50px;
      }

      .progress {
        position: relative;
        cursor: pointer;
        width: calc(100% - 100px);
        height: 8px;
        margin: 10px;
        background: #ffffff1a;
        border-radius: 5px;
      }

      .volume {
        position: relative;
        cursor: pointer;
        width: 80px;
        height: 8px;
        margin-left: 20px;
        background: #ffffff1a;
        border-radius: 5px;
      }
    }
  }

  /* The time controls section */

  .timecontrols {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    position: absolute;
    bottom: 4rem;
    margin-left: 10vw;
  }

  .time_progressbarContainer {
    background-color: gray;
    border-radius: 15px;
    width: 75vw;
    height: 5px;
    z-index: 30;
    position: relative;
    margin: 0 20px;
  }

  .time_progressBar {
    border-radius: 15px;
    background-color: indigo;
    height: 100%;
  }

  .controlsTime {
    color: white;
  }
}

.ai-tool {
  max-width: 1250px;
  margin: auto;
  padding: 40px 10vw 50px 10vw;
  width: 100%;

  &.image {
    display: flex;
    flex-direction: row;

    textarea.textInput {
      width: calc(100% - 300px);
      height: 300px;
      max-height: 300px;
      min-height: 300px;
    }

    .results {
      position: relative;
      width: 300px;
      min-height: 300px;

      .blueButton {
        position: absolute;
        right: 0px;
        left: 0px;
        bottom: 20px;
        margin: auto;
      }
    }
  }

  textarea.textInput {
    width: 100%;
    height: 300px;
    max-height: 300px;
    min-width: auto;
    border: 1px solid #e8eced;
    padding: 15px;
    resize: none;
    color: rgba(9, 50, 58, 0.5019607843);
    font-size: 18px;
    font-weight: 500;
    line-height: 29px;
  }

  .blueButton {
    width: fit-content;
    margin: 30px auto;
  }

  .resultsimage {
    display: flex;

  }

  .results {
    display: flex;
    width: 100%;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid rgba(223, 228, 229, 0.658824);
    box-shadow: 4px 25px 45px 0px rgba(112, 135, 139, 0.1);
    background-image: url(https://storage.googleapis.com/dratini/Background3.png);
    background-position: right;
    background-size: cover;
    background-repeat: no-repeat;
    justify-content: center;
    align-items: center;
    max-width: 1250px;

    .text {
      width: 100%;
      color: rgba(9, 50, 58, 0.5019607843);
      font-size: 18px;
      line-height: 29px;
      font-weight: 600;
    }
  }
}

@media (max-width: 700px) {
  .ai-tool.image {
    display: flex;
    flex-direction: column;

    textarea.textInput {
      width: 100%;
      margin-bottom: 20px;
    }

    .results {
      margin: auto;
    }
  }

}

@media (max-width: 1280px) {
  .index h1 {
    font-size: 25px;
  }

  .index h4 {
    font-size: 14px;
  }
}

@media (max-width: 1200px) {
  .plan .teammate {
    width: 50%;
  }

  .single .subtitle .length {
    display: none;
  }

  .allplan {
    max-width: 80vw;
    width: calc(80vw - 370px);
  }
}

@media (max-width: 1100px) {
  .compare .block .feature img {
    height: 25px;
  }

  .compare .block span {
    font-size: 14px;
    height: 33px;
  }
}

@media (max-width: 1080px) {
  .index h1 {
    font-size: 20px;
  }

  .index h4 {
    font-size: 12px;
    padding-bottom: 20px;
  }
}

@media (max-width: 1000px) {
  .index {
    min-width: 250px;
  }

  .postit {
    min-width: calc(100% - 50px);
    max-width: calc(100% - 50px);
  }

  .allplan {
    max-width: 90vw;
    width: calc(90vw - 320px);
  }
}

@media (max-width: 900px) {
  .allplan {
    max-width: 90vw;
    width: calc(90vw - 320px);
  }

  .index .clickable {
    width: 140px;
  }

  .index {
    width: 220px;
  }
}

@media (max-width: 890px) {
  .index h1 {
    font-size: 20px;
  }

  .index h4 {
    font-size: 12px;
    padding-bottom: 20px;
  }
}

@media (max-width: 850px) {
  .index {
    display: none;
  }

  .subplan {
    flex-direction: column;
  }

  .index.plan {
    /*display: none;*/
    position: relative;
    align-items: center;
    min-width: 90%;
    margin: -100px 5% 0 5%;
  }

  .index .clickable {
    width: 100%;
  }

  .index.plan .title {
    width: 100%;
  }

  .articlefull {
    max-width: 80vw;
    width: 80vw;
  }

  .article {
    width: 100%;
    max-width: 100%;
  }

  .allplan {
    width: 90vw;
    margin: 0 5vw;
  }

  .plan {
    width: 100%;
  }

  .allplan .metrics {
    margin-left: -5vw;
    width: 100vw;
  }
}

@media (max-width: 740px) {
  .metrics {
    margin-left: -8vw;
    width: 96vw;
  }
}

@media (max-width: 680px) {
  header .menu>a.pricing {
    display: none;
  }

  header .menu div.signin.mobile {
    display: none;
  }
}

@media (max-width: 520px) {
  header .menu>.signin {
    display: none;
  }
}

@media (max-width: 380px) {
  header .menu>.signup {
    display: none;
  }

  header .menu div#menumobile.menumobile>a {
    padding-right: 20px;
    padding-left: 20px;
  }
}

@media (max-width: 450px) {
  .plan .teammate {
    width: 100%;
  }

  header .menu a.signup {
    display: none;
  }
}

@media (max-width: 400px) {
  header .menu a.signup {
    display: none;
  }
}

.calculator-cost {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  .content {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1200px;
    gap: 20px;
  }
  .apps-selection {
    flex: 1;
    padding: 20px;
  }
  .apps-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  .app-icon {
    width: 60px;
    height: 60px;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background: #f4f4f4;
    transition: background 0.3s;
    &.selected {
      border: 2px solid #0fc8ef;
      &::before {
        content: '✓';
        position: absolute;
        top: -10px;
        right: -10px;
        width: 20px;
        height: 20px;
        background: #0fc8ef;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-size: 1rem;
      }
    }
    &:hover .app-tooltip {
      display: block;
    }
    img {
      max-width: 40px;
      max-height: 40px;
      width: auto;
      height: auto;
    }
    .app-tooltip {
      position: absolute;
      top: -40px;
      left: 50%;
      transform: translateX(-50%);
      background: #fff;
      border: 1px solid #ddd;
      border-radius: 5px;
      padding: 5px 10px;
      font-size: 0.875rem;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      white-space: nowrap;
      z-index: 10;
      max-width: none;
      display: none;
    }
  }
  .alert {
    color: red;
    margin-bottom: 10px;
  }
  .employees-count {
    margin-top: 20px;
    label {
      display: block;
      margin-bottom: 46px;
      font-size: 1rem;
    }
  }

  .slider-container {
    position: relative;
    width: 100%;
  }

  .slider-container input[type="range"] {
    width: 100%;
    -webkit-appearance: none;
    appearance: none;
    height: 8px;
    background: linear-gradient(to right, #0fc8ef 0%, #ddd 100%);
    outline: none;
    opacity: 0.7;
    transition: opacity 0.2s;
  }

  .slider-container input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    background: #0fc8ef;
    cursor: pointer;
    border-radius: 50%;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2);
  }

  .slider-container input[type="range"]::-moz-range-thumb {
    width: 20px;
    height: 20px;
    background: #0fc8ef;
    cursor: pointer;
    border-radius: 50%;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2);
  }

  .slider-container .tooltip {
    position: absolute;
    top: -40px;
    background: #fff;
    border: 1px solid #0fc8ef;
    border-radius: 5px;
    padding: 5px 10px;
    font-size: 0.875rem;
    white-space: nowrap;
    transform: translateX(-50%);
    z-index: 10;
  }

  .employees-value {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    font-size: 1rem;
    text-align: center;
    position: relative;
  }

  .employees-value span {
    position: absolute;
    text-align: center;
  }

  .employees-value span:nth-child(1) {
    left: 0%;
    transform: translateX(-50%);
  }

  .employees-value span:nth-child(2) {
    left: 37%;
    transform: translateX(-50%);
  }

  .employees-value span:nth-child(3) {
    left: 72%;
    transform: translateX(-50%);
  }

  .employees-value span:nth-child(4) {
    left: 97%;
    transform: translateX(-50%);
  }

  .cost-calculation {
    flex: 1;
    padding: 20px;
    border-top: 1px solid #ddd;
    /* Add top border for separation */
    margin-top: 20px;
    /* Add margin for spacing */
  }

  .cost-calculation h2 {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }

  .cost-details {
    text-align: left;
  }

  .cost-details h3 {
    font-size: 1.25rem;
    margin-top: 20px;
  }

  .total-cost {
    font-size: 2rem;
    color: #10c8ef;
    font-weight: bold;
  }

  .quote-link {
    margin-top: 10px;
    font-size: 1rem;
    color: #333;
    /* Changed to black */
  }

  .quote-link a {
    font-weight: bold;
    /* Make link text bold */
    color: #000;
    /* Black color for the link */
    text-decoration: none;
  }

  .quote-link a:hover {
    text-decoration: underline;
  }

  .testimonial-block {
    display: block;
    text-decoration: none;
    color: inherit;
  }

  .testimonial {
    margin-top: 20px;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid #ddd;
    background: #f9f9f9;
    text-align: left;
    /* Changed to left-align for readability */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }

  .testimonial:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  }

  .testimonial .rating {
    font-size: 1.5rem;
    color: #f5a623;
  }

  .testimonial-text {
    font-size: 1.125rem;
    /* Slightly larger font size for readability */
    line-height: 1.5;
    /* Increased line height for readability */
    margin: 10px 0;
    /* Added margin for spacing */
  }

  .testimonial .author {
    display: block;
    margin-top: 10px;
    font-style: italic;
    color: #333;
    font-size: 1rem;
  }

  .slot-animation {
    animation: slot-machine 0.5s ease-in-out;
    /* Faster animation */
  }

  .slot-animation-slow {
    animation: slot-machine 0.7s ease-in-out;
    /* Slightly slower animation */
  }

  .slot-animation-fast {
    animation: slot-machine 0.3s ease-in-out;
    /* Fastest animation */
  }

}

@media (max-width: 768px) {
  .calculator-cost {
    .cost-calculation {
      padding: 0px;
    }

    .content {
      flex-direction: column;
      gap: 20px;
      /* Maintain gap between sections for mobile */
    }
    .apps-selection {
      border-right: none;
      padding: 0px;
    }

    .apps-list {
      display: flex;
      flex-wrap: wrap;
    }

    .app-icon {
      width: 50px;
      /* Further reduced size for smaller screens */
      height: 50px;
      /* Further reduced size for smaller screens */
    }

    .app-icon img {
      max-width: 30px;
      /* Further reduced size for smaller screens */
      max-height: 30px;
      /* Further reduced size for smaller screens */
    }

    .testimonial {
      padding: 15px;
      /* Adjusted padding for smaller screens */
    }

    .testimonial-text {
      font-size: 1rem;
      /* Slightly smaller font size for smaller screens */
    }
    .app-tooltip {
      left: 50%;
      transform: translateX(-50%);
      max-width: 150px;
      /* Set max width for smaller screens */
      white-space: normal;
      /* Allow text to wrap */
    }
  }
}

@media (min-width: 768px) {
  .calculator-cost {
    .content {
      flex-direction: row;
      /* Switch to row layout for larger screens */
      justify-content: space-between;
      gap: 40px;
      /* Increase gap between sections for larger screens */
    }

    .apps-selection {
      border-right: 1px solid #ddd;
      /* Adjust for larger screens */
    }

    .apps-list {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      /* Limit to 6 icons per row on larger screens */
      gap: 20px;
    }

    .cost-calculation {
      border-top: none;
      margin-top: 0;
      padding-top: 0;
    }



  }
}

@media (max-width: 480px) {
  .calculator-cost {
    .app-tooltip {
      left: 50%;
      transform: translateX(-50%);
      max-width: 120px;
      /* Further restrict max width for smaller screens */
      font-size: 0.75rem;
      /* Adjust font size for smaller screens */
      padding: 5px;
      /* Adjust padding for smaller screens */
      white-space: normal;
      /* Allow text to wrap */
    }
  }
}